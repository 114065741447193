import { ClientDetails, NoteDetails } from "../../types";
import { Typography } from "@mui/material";
import { NoPendingNote, PendingNote, UploadSession } from "./note-cards";
import notesStyle from "./style/notes.module.css"
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import Carousel from "react-multi-carousel";
import { ClientCard } from "./client-card";
import { Search } from "../../components/search";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 40
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 0
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};


export const Notes = ({ pendingNotes, clientRecords, handleFileUpload }: { pendingNotes: NoteDetails[], clientRecords: ClientDetails[], handleFileUpload: any }) => {
    return (
        <div>
            <Typography variant="h4">Pending Review ({pendingNotes.length})</Typography>
            <Typography variant="body2" color={"grey"} fontSize={14}>The following notes require some action</Typography>
            <br />
            <div className={notesStyle.carousel_container}>
                <Carousel responsive={responsive} partialVisible={true} sliderClass={notesStyle.slider} itemClass={notesStyle.item}>
                    {/* <UploadSession handleFile={handleFileUpload} /> */}
                    {pendingNotes.length == 0 && <NoPendingNote />}
                    {pendingNotes.length > 0 && pendingNotes.map((note, idx) => {
                        return <PendingNote name={(note.name == "" || note.name == null) ? "John Doe" : note.name} title={`🕑 ${moment(note.timestamp).format("MMM Do hh:mm A")}`} note={note} key={`pendng-note-${idx}`} />
                    })}
                </Carousel>
            </div>
            <div className={notesStyle.pending_notes}>
            </div>
            <br />
            <div className={notesStyle.history_title}>
                <div>
                    <Typography variant="h4">History</Typography>
                    <Typography variant="body1" color={"grey"} fontSize={14}>Your client history</Typography>

                </div>
                {/* <Search /> */}
            </div>
            <br />
            <div className={notesStyle.clients}>
                {clientRecords.length == 0 && <ClientCard clientDetails={{
                    client_name: "No Record",
                    id: "",
                    creation_timestamp: 0,
                    report_count: 0,
                    last_update: moment().unix() * 1000,
                    last_booking_timestamp: 0
                }} />}
                {clientRecords.map((record, idx) => {
                    return <ClientCard clientDetails={record} key={`record_client-${idx}`} />
                })}
            </div>
            <br />
            <br />
        </div>
    );
}