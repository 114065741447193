import { Box, Chip, Typography } from "@mui/material";
import sereneProfileStyle from "./style/serene-profile.module.css";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../types";
import moment from "moment";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Edit } from "../../components/edit";
import { useEffect, useState } from "react";
import { updateSereneUserData } from "../../helper/user-helper";
import { EXPERTISE_OPTIONS, LANGUAGE_KEYS, LANGUAGE_OPTIONS } from "../../const";
import EditDialog from "./edit-dialog";
import ExpertiseDialog from "./expertise-dialog";
import AboutDialog from "./about-dialog";
import LanguageDialog from "./language-dialog";
import ScheduleSetting from "../serene/scheduling/schedule-setting";

export const SereneProfile = () => {
    const navigate = useNavigate();
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openAboutDialog, setOpenAboutDialog] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [aboutText, setAboutText] = useState('');
    const [openExpertiseDialog, setOpenExpertiseDialog] = useState(false);
    const [selectedExpertise, setSelectedExpertise] = useState<string[]>([]);
    const [openLanguageDialog, setOpenLanguageDialog] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredLanguages, setFilterdLanguages] = useState(LANGUAGE_KEYS)


    const sereneUser = useSelector((state: AppState) => state.sereneUser);


    useEffect(() => {
        if (sereneUser.fetched) {
            setAboutText(sereneUser.about)
            setSelectedExpertise(sereneUser.expertise)
        }

    }, [sereneUser])

    /**
     * Set the filterd languages on search
     */
    useEffect(() => {
        const filtered = LANGUAGE_KEYS.filter(language =>
            language.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilterdLanguages(filtered)
    }, [searchTerm])

    const handleOpenEditDialog = () => {
        setEditedName(sereneUser.displayName);
        setEditedEmail(sereneUser.email);
        setOpenEditDialog(true);
    };


    const handleToggleExpertise = (option: string) => {
        setSelectedExpertise(prev =>
            prev.includes(option) ? prev.filter(item => item !== option) : [...prev, option]
        );
    };
    const handleToggleLanguage = (language: string) => {
        setSelectedLanguages(prev =>
            prev.includes(language) ? prev.filter(item => item !== language) : [...prev, language]
        );
    };

    const handleSaveEdit = async () => {
        const objToUpdate: any = {};
        if (editedEmail !== sereneUser.email) objToUpdate["email"] = editedEmail;
        if (editedName !== sereneUser.displayName) objToUpdate["display_name"] = editedName;

        setOpenEditDialog(false);
        await updateSereneUserData(sereneUser.id, objToUpdate);
    };

    const handleRemoveExpertise = async (index: number) => {
        const newExpertise = selectedExpertise.filter((_, i) => i !== index)
        await updateSereneUserData(sereneUser.id, { expertise: newExpertise })
        setSelectedExpertise(newExpertise);
    };

    const handleRemoveLanguage = async (index: number) => {
        const newLanguages = selectedLanguages.filter((_, i) => i !== index);
        await updateSereneUserData(sereneUser.id, { languages: newLanguages });
        setSelectedLanguages(newLanguages);
    };



    return (
        <div className={sereneProfileStyle.container}>
            <EditDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                onSave={handleSaveEdit}
                editedName={editedName}
                editedEmail={editedEmail}
                setEditedName={setEditedName}
                setEditedEmail={setEditedEmail}
            />
            <ExpertiseDialog
                open={openExpertiseDialog}
                onClose={() => setOpenExpertiseDialog(false)}
                onSave={async () => {
                    await updateSereneUserData(sereneUser.id, {
                        expertise: selectedExpertise
                    })
                    setOpenExpertiseDialog(false)
                }}
                expertiseOptions={EXPERTISE_OPTIONS}
                selectedExpertise={selectedExpertise}
                handleToggleExpertise={handleToggleExpertise}
            />
            <AboutDialog
                open={openAboutDialog}
                onClose={() => setOpenAboutDialog(false)}
                onSave={async () => {
                    await updateSereneUserData(sereneUser.id, {
                        about: aboutText
                    })

                    setOpenAboutDialog(false)
                }}
                aboutText={aboutText}
                setAboutText={setAboutText}
            />

            <LanguageDialog
                open={openLanguageDialog}
                onClose={() => setOpenLanguageDialog(false)}
                onSave={async () => {
                    // Now, find the keys that match the values in LANGUAGE_OPTIONS
                    const matchingLanguageCodes: string[] = Object.entries(LANGUAGE_OPTIONS)
                        .filter(([_, value]) => selectedLanguages.includes(value)) // Filter for values that match the language codes
                        .map(([key]) => key); // Extract the keys

                    console.log(matchingLanguageCodes)
                    await updateSereneUserData(sereneUser.id, {
                        languages: selectedLanguages,
                        language_codes: matchingLanguageCodes
                    });
                    setOpenLanguageDialog(false);
                }}
                selectedLanguages={selectedLanguages}
                handleToggleLanguage={handleToggleLanguage}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filteredLanguages={filteredLanguages} />

            <div className={sereneProfileStyle.body}>
                <div className={sereneProfileStyle.header}>
                    <div className={sereneProfileStyle.title}>
                        <SwitchAccountIcon sx={{ fontSize: "50px", color: "black" }} />
                        <Typography variant="h3" fontWeight={"bolder"}>Profile</Typography>
                    </div>
                    <div onClick={() => navigate(-1)}>
                        <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                    </div>
                </div>
                <br />
                <div className={sereneProfileStyle.basic_container}>
                    <img src={sereneUser.displayPicture} className={sereneProfileStyle.display_picture} />
                    <div className={sereneProfileStyle.basic_details}>
                        <div className={sereneProfileStyle.row}>
                            <Typography variant="h4" fontWeight={"bolder"}>{sereneUser.displayName}</Typography>
                            <div className={sereneProfileStyle.edit_container}><Edit onClick={handleOpenEditDialog} /></div>
                        </div>
                        <Typography variant="body1">📩 {sereneUser.email}</Typography>
                        <Typography variant="body1">🕐 Member since <strong>{moment(sereneUser.creationTime ?? 0).format("MMMM DD YYYY")}</strong></Typography>
                        <Typography
                            variant="body1"
                            onClick={() => window.open(`https://serene.vybz.health/user/${sereneUser.userName}`, '_blank', 'noopener,noreferrer')}
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#0073e6', textDecoration: 'none' }}
                        >
                            <OpenInNewIcon fontSize="small" sx={{ marginRight: "5px" }} />
                            Visit your public profile

                        </Typography>

                    </div>
                </div>
                <div className={sereneProfileStyle.row}>
                    <Typography variant="h5" fontWeight={"bolder"}>
                        About Me

                    </Typography>
                    <Edit onClick={() => setOpenAboutDialog(true)} />
                </div>
                <Typography variant="body2" color={"text.secondary"}>Tell the world how amazing you are</Typography>
                <div className={sereneProfileStyle.basic_container}>
                    <Box minHeight={"150px"} textAlign={"start"} width={"100%"}>
                        <Typography variant="body1" color={"text.secondary"}>{sereneUser.about}</Typography>
                    </Box>
                </div>

                <Typography variant="h5" fontWeight={"bolder"}>Expertise</Typography>
                <Box display="flex" flexWrap="wrap" gap={1} marginTop={"10px"}>
                    {sereneUser.expertise.map((item, index) => (
                        <Chip
                            key={index}
                            label={item}
                            onDelete={() => handleRemoveExpertise(index)}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                    <Chip label="Add" onClick={() => { setOpenExpertiseDialog(true) }} color="secondary" variant="outlined" />
                </Box>
                <br />
                <Typography variant="h5" fontWeight={"bolder"}>Languages</Typography>
                <Box display="flex" flexWrap="wrap" gap={1} marginTop={"10px"}>
                    {sereneUser.languages.map((item, index) => (
                        <Chip
                            key={index}
                            label={item}
                            onDelete={() => handleRemoveLanguage(index)}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                    <Chip label="Add" onClick={() => { setOpenLanguageDialog(true) }} color="secondary" variant="outlined" />
                </Box>
                <br />
                <Typography variant="h5" fontWeight={"bolder"}>Availability</Typography>
                <Typography variant="body2" color={"text.secondary"}>Modify when someone can book a session with you</Typography>
                <div className={sereneProfileStyle.basic_container}>
                    <Box width={"100%"}>
                        <ScheduleSetting onSuccess={undefined} userId={sereneUser.id}
                            buttonText="Save"
                            currentAvailability={sereneUser.availability} />
                    </Box>
                </div>
            </div>
        </div>
    );
};