import landingpageStyle from "./style/landingpage.module.css";
import VybzAppLogo from "../../../assets/logo.svg"
import { motion, useScroll, useTransform } from "framer-motion"
import SectionOne from "./section-one";
import SectionTwo from "./section-two";
import SectionThree from "./section-three";
import SectionFour from "./section-four";
import SectionEnd from "./section-end";
import SectionFive from "./section-five";
import { VybzFooter } from "../../../components/footer";
import '../../../App.css';



const LandingPage = () => {
    const { scrollYProgress } = useScroll();
    const sectionOneopacity = useTransform(
        scrollYProgress,
        [0, 0.1],
        [1, 0],
    )


    return (
        <div className="App">
            <motion.div className={landingpageStyle.header}>
                <img src={VybzAppLogo} className={landingpageStyle.header_logo} />
                <div className={landingpageStyle.header_title} >Vybz{" "}<span className={landingpageStyle.header_title_non_bold}>Health</span></div>
            </motion.div>
            <SectionOne sectionOneopacity={sectionOneopacity} />
            <SectionTwo />
            <SectionThree />
            {/* Second Section */}
            <SectionFour />
            <SectionFive />
            <SectionEnd />
            <VybzFooter />


        </div >
    );

}


export default LandingPage;
