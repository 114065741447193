import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Checkbox,
    ListItemText,
    Button
} from '@mui/material';

interface ExpertiseDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    expertiseOptions: string[];
    selectedExpertise: string[];
    handleToggleExpertise: (option: string) => void;
}

const ExpertiseDialog: React.FC<ExpertiseDialogProps> = ({
    open,
    onClose,
    onSave,
    expertiseOptions,
    selectedExpertise,
    handleToggleExpertise
}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Select Expertise</DialogTitle>
            <DialogContent>
                {expertiseOptions.map((option) => (
                    <MenuItem key={option} value={option} onClick={() => handleToggleExpertise(option)}>
                        <Checkbox checked={selectedExpertise.includes(option)} />
                        <ListItemText primary={option} sx={{ marginLeft: "5px" }} />
                    </MenuItem>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} sx={{ borderRadius: "5px", marginRight: "20px" }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExpertiseDialog;
