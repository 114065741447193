import React, { useState } from "react";
import { Box, Typography, Button, Snackbar, Alert, Chip, MenuItem, TextField, Checkbox, ListItemText, Select, InputLabel, FormControl } from "@mui/material";
import { updateSereneUserData } from "../../../helper/user-helper";
import { TextInput } from "flowbite-react";
import { EXPERTISE_OPTIONS } from "../../../const";

export const ProfessionalDetails = ({ userId, onSubmit }: { userId: string, onSubmit: any }) => {
    const [expertise, setExpertise] = useState<string[]>([]); // Track selected areas of expertise
    const [organization, setOrganization] = useState(""); // Optional field for organization
    const [yearsExperience, setYearsExperience] = useState(0); // Optional field for years of experience
    const [error, setError] = useState(false); // To track errors
    const [toastMessage, setToastMessage] = useState(""); // Toast message content
    const [openToast, setOpenToast] = useState(false); // To toggle toast visibility

    // Expanded expertise options


    const handleExpertiseChange = (event: any) => {
        const { value } = event.target;
        setExpertise(value);
    };

    const handleDeleteChip = (chipToDelete: string) => {
        setExpertise(expertise.filter((item) => item !== chipToDelete)); // Remove expertise chip
    };

    const handleSubmit = () => {
        if (expertise.length === 0) {
            // Show error if expertise is empty
            setToastMessage("Please select at least one area of expertise."); // Set error message
            setOpenToast(true); // Open the toast notification
            return; // Exit the function early
        }

        // Save to firebase
        updateSereneUserData(userId, {
            expertise,
            organization,
            experience: yearsExperience,
        });
        onSubmit();
    };

    const handleCloseToast = () => {
        setOpenToast(false); // Close the toast notification
    };

    return (
        <>
            <Typography variant="h6">Expertise *</Typography>
            <Box mt={1}>
                {/* Expertise Select */}
                <FormControl fullWidth variant="outlined">
                    <Select
                        multiple
                        value={expertise}
                        onChange={handleExpertiseChange}
                        renderValue={(selected: string[]) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} onDelete={() => handleDeleteChip(value)} />
                                ))}
                            </Box>
                        )}
                    >
                        {EXPERTISE_OPTIONS.map((option) => (
                            <MenuItem key={option} value={option}>
                                <Checkbox checked={expertise.indexOf(option) > -1} />
                                <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Organization (Optional)</Typography>
                <TextInput
                    placeholder="Organization"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    className="mt-2 w-full"
                />
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Years of Experience (Optional)</Typography>
                <TextInput
                    type="number"
                    placeholder="How long have you been practicing?"
                    value={yearsExperience}
                    onChange={(e) => {
                        const years = parseInt(e.target.value)
                        if (years >= 0) {
                            setYearsExperience(years)
                        }
                    }
                    }
                    className="mt-2 w-full"
                />
            </Box>

            <Box mt={4} display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={handleSubmit} sx={{ paddingRight: "50px", paddingLeft: "50px", borderRadius: "30px" }}>
                    Next
                </Button>
            </Box>

            {/* Toast Notification */}
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleCloseToast} severity="error" sx={{ width: "100%" }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ProfessionalDetails;
