import { Typography, Chip } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CancelIcon from '@mui/icons-material/Cancel';
import notificationStyle from "./style/notification.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../types";
import { getFirestore, collection, updateDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { readNotifications } from "../../../slice/notification-slice";
import moment from "moment";

export const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const notifications = useSelector((state: AppState) => state.notification);
    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const db = getFirestore();

    // Function to mark all notifications as read
    const markAllNotificationsRead = async () => {
        try {
            const notificationsRef = collection(db, `serene_users/${sereneUser.id}/notifications`);

            // Update all notifications to status "read"
            for (const notification of notifications.notifications) {
                if (notification.status === "unread") {
                    const docRef = doc(notificationsRef, notification.id);
                    await updateDoc(docRef, { status: "read" });
                }
            }
        } catch (error) {
            console.error("Error updating notifications:", error);
        }
    };

    // Mark notifications as read when the page is closed
    useEffect(() => {
        return () => {
            markAllNotificationsRead();
            dispatch(readNotifications({}))

        };
    }, []); // Empty dependency array ensures this runs only when the component unmounts

    return (
        <div className={notificationStyle.container}>
            <div className={notificationStyle.body}>
                <div className={notificationStyle.header}>
                    <div className={notificationStyle.title}>
                        <NotificationsNoneIcon sx={{ fontSize: "50px", color: "black" }} />
                        <Typography variant="h3" fontWeight={"bolder"}>Notifications</Typography>
                    </div>
                    <div onClick={() => navigate(-1)}>
                        <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                    </div>
                </div>
                <>
                    <div className={notificationStyle.notifications}>
                        {notifications.notifications.length == 0 && <div className={notificationStyle.notification} key={"none"}>
                            <Typography variant="h5" display="flex" alignItems="center">
                                No Notifications
                            </Typography>
                            <Typography mt={"10px"}>Your do not have any notifications</Typography>
                        </div>}
                        {notifications.notifications.map((d) => (
                            <div className={notificationStyle.notification} key={d.id}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="h5" display="flex" alignItems="center">
                                        {d.title}
                                        {d.status === "unread" && (
                                            <Chip
                                                label="Unread"
                                                color="primary"
                                                size="small"
                                                sx={{ marginLeft: "10px", padding: "10px" }}
                                            />
                                        )}
                                    </Typography>
                                    <Typography variant="body2" color={"grey"} fontSize={"14px"}>
                                        {moment(d.timestamp).format("MMM Do, h:mm a")}
                                    </Typography>
                                </div>
                                <Typography mt={"10px"}>{d.description}</Typography>
                            </div>
                        ))}
                    </div>

                </>
            </div>

        </div>
    );
};
