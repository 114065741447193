import { Box, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import onboardingStyle from "./style/onboarding.module.css"
import { useEffect, useState } from "react";
import PersonalDetails from "./personal-details";
import { useSelector } from "react-redux";
import { ArrowBack, Close } from "@mui/icons-material";
import { AppState } from "../../../types";
import { useNavigate } from "react-router-dom";
import ProfessionalDetails from "./professional-details";
import { updateSereneUserData } from "../../../helper/user-helper";
import completeAnimation from '../../../assets/complete_animation.json';
import Lottie from "lottie-react";

export const Onboarding = ({ }) => {

    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const [step, setStep] = useState<number>(0)
    const navigate = useNavigate();


    useEffect(() => {
        if (step == 2) {
            updateSereneUserData(sereneUser.id, { profile_complete: true })
            setTimeout(() => {
                navigate("/dashboard")
            }, 2000)
        }
    }, [step])

    const getTitle = () => {
        switch (step) {
            case 0:
                return "Personal Details";
            case 1:
                return "Professional Details";
            case 2:
                return "Done";
        }
    }

    const getSubTitle = () => {
        switch (step) {
            case 0:
                return "Help us get to know you better";
            case 1:
                return "This help us create personalized services for you";
            case 2:
                return "Your personalized profile is ready!";
        }
    }


    const getBody = () => {
        switch (step) {
            case 0:
                return <PersonalDetails currentName={sereneUser.displayName}
                    currentEmail={sereneUser.email}
                    userId={sereneUser.id}
                    onSubmit={() => { setStep(1) }} />
            case 1:
                return <ProfessionalDetails
                    userId={sereneUser.id}
                    onSubmit={() => { setStep(2) }} />
            case 2:


                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", }}>
                        <Lottie animationData={completeAnimation} style={{ height: "250px" }} loop={false} />
                        <br />
                        <Typography >You will be redirected to the dashboard ...</Typography>
                        <br />
                    </div>
                );
        }
    }

    return <div className={onboardingStyle.container}>
        <Stepper activeStep={step}>
            {["Personal", "Professional", "Complete"].map((label, key) => {
                return <Step key={key}>
                    <StepLabel>{label}</StepLabel>
                </Step>;
            })}
        </Stepper>
        <div>
            <br />
            <br />
            <Typography variant="h3">{getTitle()}</Typography>
            <Typography variant="body1" color={"grey"}>{getSubTitle()}</Typography>
        </div>
        <br />
        <div className={onboardingStyle.body}>
            {getBody()}
        </div>
        <br />
        <div>
            <Box display="flex" justifyContent="flex-end">
                <IconButton
                    onClick={() => { setStep(step - 1); }}
                    disabled={step === 0}
                    sx={{ marginRight: "10px" }}
                >
                    <ArrowBack sx={{ color: step === 0 ? 'grey' : 'black', fontSize: 30, }} />
                </IconButton>
                <IconButton
                    onClick={() => { navigate("/dashboard"); }}
                >
                    <Close sx={{ color: 'black', fontSize: 30, }} />
                </IconButton>
            </Box>
            <br />
        </div>

    </div>
}