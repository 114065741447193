import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slice/user-slice'
import sereneUserSlice from "./slice/serene-user"
import calenderSlice from './slice/calender-slice';
import notificationSlice from './slice/notification-slice';
import clientSlice from './slice/client-slice';

const store = configureStore({
    reducer: {
        user: userSlice,
        sereneUser: sereneUserSlice,
        calender: calenderSlice,
        notification: notificationSlice,
        clients: clientSlice
    },
})

export default store;
