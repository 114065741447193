import { createSlice } from '@reduxjs/toolkit'
import { BookingData } from '../types';

/**
 * Calender data
 */
export const calenderSlice = createSlice({
    name: 'calender',
    initialState: {
        fetched: false,
        events: [] as BookingData[],
    },
    reducers: {
        updateCalenderData: (state, action) => {
            state.fetched = true;
            state.events = action.payload ?? []
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateCalenderData } = calenderSlice.actions

export default calenderSlice.reducer

