import { useSelector } from "react-redux"
import { AppState } from "../../../types"
import calenderStyle from "../style/calender.module.css"
import { Box, Button, Typography } from "@mui/material"
import { Clipboard } from "flowbite-react"
import moment from "moment"
import { formatTimeDifference } from "../../../helper/time-helper"
import { useNavigate } from "react-router-dom"
import { PastBookings } from "../scheduling/past-bookings"


export const Calender = ({ }) => {

    const sereneUser = useSelector((state: AppState) => state.sereneUser)
    const calender = useSelector((state: AppState) => state.calender)
    const navigate = useNavigate();

    // filter out events scheduled for today
    // const eventsToday = calender.events.filter((data) => {
    //     const currentDay = moment().format("DD-YY-MMMM")
    //     const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
    //     return currentDay == calenderDate
    // })

    // // filter out events which are not scheduled for today
    // const eventsLater = calender.events.filter((data) => {
    //     const currentDay = moment().format("DD-YY-MMMM")
    //     const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
    //     return currentDay != calenderDate
    // })

    const personalPageLink = `https://serene.vybz.health/user/${sereneUser.userName}`

    return (
        <div>
            <Typography variant="h4">Scheduling Page</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Share your personalized link for easy scheduling</Typography>
            <div className={calenderStyle.item_container}>
                <Typography variant="body1" >🔗 <a href={personalPageLink} className={calenderStyle.link}><span>{personalPageLink}  </span></a></Typography>
                {sereneUser.calenderConnected && <div className={calenderStyle.clipboard}><Clipboard.WithIconText valueToCopy={personalPageLink} /></div>}
            </div>
            <br />
            {/* <Typography variant="h4">Today</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>View all your scheduled sessions for today</Typography>
            {eventsToday.length == 0 && <div className={calenderStyle.item_container}>
                <Typography variant="body1" color={"grey"} >You have a free schedule today!</Typography>
            </div>}
            {eventsToday.map((event) => {
                console.log(JSON.stringify(event))
                return <div className={calenderStyle.item_container}>
                    <div>
                        <Typography variant="body1">🕐 {moment(event.start_time).format("hh:mm A")}</Typography>
                    </div>
                </div>
            })}
            <br />
            
            <br />
            <br /> */}
            <Typography variant="h4">Upcoming ({calender.events.length})</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Stay on top of your upcoming appointments and plan ahead</Typography>
            {calender.events.length == 0 && <div className={calenderStyle.item_container}>
                <Typography variant="body1" color={"grey"} >No upcoming sessions!</Typography>
            </div>}
            {calender.events.map((event) => {
                const bookingId = event.extendedProperties?.private?.bookingId ?? ""
                return <div className={calenderStyle.item_container}>
                    <div>
                        <Typography variant="h5" fontWeight={"bolder"}>{event?.extendedProperties?.private?.name ?? ""}</Typography>
                        <Typography variant="body2">{moment(event.start.dateTime).format("Do MMM HH:mm A")}</Typography>
                        <Typography variant="body2" fontSize={12} color={"grey"}>{`In ${formatTimeDifference(event.start.dateTime)}`}</Typography>
                    </div>
                    <Box display={"flex"} flexGrow={1} />
                    <Box flexDirection={"column"} display={"flex"} width={"150px"} gap={"5px"} alignSelf={"flex-end"}>
                        <Button variant="outlined" disableElevation
                            sx={{
                                borderRadius: "20px"
                            }}
                            fullWidth
                            onClick={() => {
                                window.open(event.hangoutLink, '_blank')?.focus()
                            }}>
                            Join
                        </Button>
                        <Button variant="outlined" color="primary"
                            disableElevation
                            sx={{
                                borderRadius: "20px"
                            }}
                            fullWidth
                            onClick={() => {
                                navigate(`/booking/${bookingId}`)
                            }}>
                            Modify
                        </Button>
                    </Box>
                </div>
            })}
            <br />
            <Typography variant="h4">Past Sessions</Typography>
            <PastBookings />
        </div>
    )
}