import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { motion, MotionValue } from 'framer-motion';
import Lottie from "lottie-react";
import animation from '../../../assets/serene_animation.json';
import hipaa from '../../../assets/hipaa.png';
import landingpageStyle from "../style/section-one.module.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Helmet } from 'react-helmet';
import { useRef } from 'react';

const SectionOne = () => {
    const lottieRef = useRef();

    // useEffect(() => {
    //     if (lottieRef.current) {
    //         //@ts-ignore
    //         lottieRef?.current?.setSpeed(0.7);
    //     }
    // }, [lottieRef])

    return (
        <>
            <Helmet>
                <title>Serene | Revolutionizing Mental Health Care</title>
                <meta name="description" content="Serene is a mental health platform for clinicians, empowering them to deliver outstanding care with intelligent, data-driven tools that enhance every aspect of mental health support." />
                {/* Open Graph Tags for Facebook, WhatsApp, and other platforms */}
                <meta property="og:title" content="Serene | Revolutionizing Mental Health Care" />
                <meta property="og:description" content="Serene is a mental health platform for clinicians, empowering them with data-driven tools for enhanced mental health support." />
                <meta property="og:url" content="https://serene.vybz.health" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://serene.vybz.health/logo.png" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Serene | Revolutionizing Mental Health Care" />
                <meta name="twitter:description" content="Serene is a platform empowering clinicians with intelligent tools for better mental health support." />
                <meta name="twitter:image" content="https://serene.vybz.health/logo.png" />
                <meta name="twitter:url" content="https://serene.vybz.health" />

                {/* WhatsApp and other Open Graph enabled platforms */}
                <meta property="og:site_name" content="Serene" />
            </Helmet>
            <div className={landingpageStyle.section_one_container}>
                <div className={landingpageStyle.section_one}>
                    <br />
                    <br />
                    <br />
                    <motion.p className={landingpageStyle.introtext}>
                        <Typography variant="h3" fontWeight={"bolder"}><span className={landingpageStyle.introtext_part}>Revolutionizing</span> Mental Health Care</Typography>
                        {/* <span className={landingpageStyle.fade_in_initial}></span> */}
                    </motion.p>
                    <div className={landingpageStyle.fade_in_subtitle}>
                        <Typography variant="body2" fontSize={22}>
                            <span className="bold">AI Copilot</span> for clinicians that enhance every aspect of mental health support.
                        </Typography>
                    </div>
                    <div className={landingpageStyle.call_to_action}>
                        <Button size='large'
                            endIcon={<ArrowRightIcon />}
                            onClick={() => {
                                window.open("https://calendly.com/anurag-vybz/serene-demo", '_blank')?.focus()
                            }} disableElevation variant='contained' sx={{ background: "black", borderRadius: "40px", paddingTop: "12px", paddingBottom: "12px", paddingLeft: "40px", paddingRight: "40px", fontWeight: "bolder", opacity: 0.8, height: "60px" }}> Request Demo </Button>
                        <img className={landingpageStyle.hipaa} src={hipaa} />
                    </div>
                    {/* <div className={landingpageStyle.call_to_action_section}>
                    <div className={landingpageStyle.call_to_action}>
                        <div className={landingpageStyle.call_to_action_text}><Typography variant="body1" color="black">Join our growing network of over <span className={landingpageStyle.underline}><span className="bold">300+ clinicians</span></span></Typography></div>
                        <Button onClick={() => {
                            window.open("https://calendly.com/anurag-vybz/serene-demo", '_blank')?.focus()
                        }} disableElevation variant='contained' sx={{ background: "black", borderRadius: "20px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "40px", paddingRight: "40px" }}> Request Demo </Button>
                    </div>
                </div> */}


                </div>

                <div className={landingpageStyle.section_one_img}>
                    {/* @ts-ignore  */}
                    <Lottie lottieRef={lottieRef} animationData={animation} loop={true} />
                </div>

            </div>
            <div className={landingpageStyle.call_to_action_text}><Typography variant="body1" color="grey">Join our growing network of over 300+ clinicians today. Get started in <span style={{ textDecoration: "underline", fontWeight: "bolder" }}>less than a minute</span>!</Typography></div>
            {/* <Typography variant="body1" color="text.secondary" fontSize={16}>
                Empowering clinicians to deliver outstanding care with <span className="bold">intelligent, data-driven tools</span> that enhance every aspect of mental health support.
            </Typography> */}
        </>);
}

export default SectionOne;