import { Auth, signInWithPopup, User } from "firebase/auth";
import GoogleButton from 'react-google-button'
import { GoogleAuthProvider } from "firebase/auth";
import loginStyles from "./style/login.module.css";
import VybzLogo from "../../assets/logo.svg"
import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { getFirebaseFirestore, USER_COLLECTION } from "../../const";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../hooks/use-auth";
import Cookie from "js-cookie";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from "framer-motion";
// This code runs inside of an iframe in the extension's offscreen document.
// This gives you a reference to the parent frame, i.e. the offscreen document.
// You will need this to assign the targetOrigin for postMessage.
const PARENT_FRAME = document.location.ancestorOrigins[0];
let isPopupOpen = false;

const Login = ({ loginType = "" }: { loginType: string }) => {
    const { pending, isSignedIn, user, auth } = useAuth();
    const params = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const type = loginType == "" ? (params["type"] ?? "") : loginType
    const isRequestFromExtension = searchParams.get("from-extension")
    const isRequestFromCopilot = searchParams.get("from-copilot")
    const [showAnimaion, setShowAnimation] = useState(false);

    // check if the domain is app or serene
    const getSubdomain = () => {
        const host = window.location.host;
        const subdomain = host.split('.')[0];
        return subdomain;
    };

    const subdomain = getSubdomain();

    const handleRedirection = () => {
        navigate("/dashboard")
    }

    useEffect(() => {
        // handle login if user is present
        if (user) {
            Cookie.set("serene-auth", user?.uid ?? "", {
                domain: "vybz.health"
            });
            // if subdomain is serene and user is missing then create user
            if (subdomain == "serene") {
                const userDocRef = doc(getFirebaseFirestore(), USER_COLLECTION, user.uid);
                getDoc(userDocRef).then((doc) => {
                    if (!doc.exists()) {
                        setDoc(userDocRef, {
                            creation_time: moment().unix() * 1000,
                            display_name: user.displayName,
                            display_picture: user.photoURL,
                            else: user.email,
                            id: user.uid
                        })
                    }
                })
            }

            if (!isRequestFromExtension) {
                handleRedirection()
            } else {
                setShowAnimation(true)
            }
        }
    }, [user])


    useEffect(() => {
        if (isRequestFromCopilot) {
            console.log("Auth request from extension")
            loginWithGoogle(auth, "serene")
        }

    }, [])


    if (isRequestFromCopilot) {
        return null;
    }


    return (<div className={loginStyles.container}>
        <div className={loginStyles.header}>
            <img src={VybzLogo} className={loginStyles.logo} />
        </div>
        <br />
        <div className={loginStyles.login_section}>
            <div className={loginStyles.header}>
                <Typography variant="h2">Welcome to <strong>{subdomain === "app" ? "Vybz" : "Serene"}</strong></Typography>
                <br />
                <Typography variant="body1" color={"grey"}>Get access to your personalized toolkit by signing in to your account</Typography>
            </div>
            <br />
            <br />
            <br />
            <div className={loginStyles.social_login}>
                {pending ? <CircularProgress /> : !showAnimaion && <GoogleButton
                    style={{ borderRadius: "5px", fontWeight: "bold", alignItems: "center", display: "flex", paddingLeft: "15px" }}
                    type="light"
                    label="Continue with Google"
                    onClick={async () => {
                        await loginWithGoogle(auth, subdomain)
                    }}
                />}
                {showAnimaion && <CheckCircleIcon sx={{ color: "green", fontSize: "90px" }} />}
            </div>
            <div>
            </div>
        </div>
    </div>
    );
}


const sendResponse = (result: any) => {
    globalThis.parent.self.postMessage(JSON.stringify(result), PARENT_FRAME);
}


const loginWithGoogle = async (auth: Auth, type: string) => {

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: "select_account "
    });

    try {
        const result = await signInWithPopup(auth, provider)
        if (result.user) {
            console.log("Signed in with google");
            await saveUser(result.user, type)
            sendResponse(result)
            return true

        } else {
            console.log("Error signing in with google");
            sendResponse(result)
            return false;
        }
    } catch (error) {
        console.error("Error signing in with google", error);
        sendResponse(error)
        return false;
    }
}

// const loginWithApple = () => {
//     const auth = getAuth();
//     const provider = new OAuthProvider('apple.com');
//     signInWithRedirect(auth, provider)
// }

const saveUser = async (user: User, type: string) => {
    const db = getFirestore()
    const userId = user.uid;
    const userCollection = type == "serene" ? "serene_users" : USER_COLLECTION
    const docRef = doc(db, userCollection, userId);
    const userData = await getDoc(docRef)
    if (userData.exists()) {
        // do nothing
    } else {
        await setDoc(docRef, {
            "id": user.uid,
            "creation_time": moment().unix() * 1000,
            "display_name": user.displayName,
            "display_picture": user.photoURL,
            "email": user.email,
        })
    }
}


export default Login