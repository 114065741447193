import { Button, Typography } from "@mui/material";
import landingpageStyle from "../style/section-two.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const SectionTwo = () => {

    const navigate = useNavigate()

    return (
        <div className={landingpageStyle.second_section}>
            <div className={landingpageStyle.into_container}>
                <div>

                    <Typography variant="h5" fontWeight={"normal"} color={"grey"}>Our Mission</Typography>
                    <br />
                    <br />
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
                        <Typography variant="h3">Enabling exceptional mental health care, because everyone deserves support on their journey to well-being</Typography>
                    </motion.div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
                        <Typography variant="h5" color={"grey"}>
                            Serene empowers mental health professionals with AI tools to enhance care and streamline workflows for solo practices and organizations alike.
                        </Typography>
                    </motion.div>



                    {/* <img src={SereneDesc} />
                    <br />
                    <br />
                    <br />
                    <br /> */}
                    {/* <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}>
                        <Typography variant="h5" align="center">We provide the <strong>Best AI powered Tech</strong> so that you <strong>can provide the Best Care</strong></Typography>
                    </motion.div> */}
                    <br />
                    <br />
                </div>
            </div>

            <br />
            <motion.div className={landingpageStyle.button_container} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 2 }}>
                <Button onClick={() => { navigate("/login") }} variant="contained" size="large" sx={{ borderRadius: 30, height: 60, width: 300 }}>Create Your Free Account</Button>
            </motion.div>
        </div>)
}

export default SectionTwo;