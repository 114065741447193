import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { locales } from 'iso-lang-codes';

const firebaseConfig = {
    apiKey: "AIzaSyC_U8vAQvZL3EF-VnzoK3wd4UkZB76mJ8U",
    authDomain: "vybzz-336f4.firebaseapp.com",
    projectId: "vybzz-336f4",
    storageBucket: "vybzz-336f4.appspot.com",
    messagingSenderId: "849382629040",
    appId: "1:849382629040:web:323325ca5ceffd77b42575",
    measurementId: "G-PTESLEWYVR"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const getApp = () => app;
export const getFirebaseFirestore = () => getFirestore();
export const getFirebaseStorage = () => getStorage();
export const getFirebaseDatabase = () => getDatabase();
export const getFirebaseAuth = () => getAuth()
export const USER_COLLECTION = "users"
export const SERENE_NOTES = "serene_notes"
export const SERENE_VIDEOS = "serene_videos"
export const SERENE_AUDIO = "serene_audio"
// //aPwwpl4gfOUnVYd65civVfSctzJ3/client_records/jjh4cZcHD6aHAIeO499X
export const SERENE_USERS = "serene_users"
export const SERENE_CLIENT_RECORDS = "client_records"
export const SERENE_CLIENT_NOTES = "client_notes"
export const CALENDLY_CLIENT_ID = "il65xDqlnrPLRJYuLd-5AHYzNQK_IHkcZOlYRbjbKNU"
export const CALENDLY_SECRET = "XBXJxh1uOLdBZNL1hkQXpu6n-GOYRI5bp6pjRQRsP4A"
export const EXPERTISE_OPTIONS = [
    "OCD",
    "ADHD",
    "Trauma",
    "Children",
    "Relationship",
    "Anxiety",
    "Depression",
    "PTSD",
    "Substance Abuse",
    "Family Therapy",
    "Couples Therapy",
    "Cognitive Behavioral Therapy (CBT)",
    "Mindfulness",
    "Play Therapy",
    "Trauma-Informed Care",
    "Dialectical Behavior Therapy (DBT)",
    "Grief Counseling",
    "Crisis Intervention",
    "Stress Management",
    "Anger Management",
    "Eating Disorders",
    "Self-Esteem Issues",
    "Behavioral Issues",
    "Psychosis",
    "Workplace Issues",
    "Parenting Support",
    "Cultural Sensitivity",
    "Assertiveness Training",
    "Addiction Recovery",
    "Sexuality and Gender Identity",
    "LGBTQ+ Issues",
    "Motivational Interviewing",
    "Solution-Focused Therapy"
];

export const LANGUAGE_OPTIONS = locales();
export const LANGUAGE_KEYS = Object.values(LANGUAGE_OPTIONS)
