import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getFirebaseFirestore, SERENE_USERS } from "../../../const";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Box, Typography, Avatar, Chip, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Helmet } from 'react-helmet';
import Scheduler from "./scheduler";
import publicPageStyle from "./style/public-page.module.css"
import { SereneUser } from "../../../types";
import { getFunctions, httpsCallable } from "firebase/functions";
import PublicIcon from '@mui/icons-material/Public'; // Import the icon
import moment from "moment-timezone";

// Function to call the getPublicSchedule Firebase function
const fetchPublicSchedule = async (timestamp: number,
    timePeriod: 'day' | 'week' | 'month',
    bookingUserName: string): Promise<any> => {
    try {
        const getPublicSchedule = httpsCallable(getFunctions(), 'getPublicSchedule');
        // Call the Firebase function with the provided parameters
        const response = await getPublicSchedule({ timestamp, timePeriod, bookingUserName });
        return response.data

    } catch (error) {
        console.error('Error fetching public schedule:', error);
        throw new Error('Error fetching public schedule');
    }
};

export const PublicSereneUser = () => {
    const { userName } = useParams();
    const [userData, setUserData] = useState<SereneUser | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [month, setMonth] = useState(-1);
    const [date, setDate] = useState("");
    const [scheduleLoading, setScheduleLoading] = useState(true)
    const [freeSlots, setFreeSlots] = useState<any>({})
    const [timezone, setTimezone] = useState(moment.tz.guess()); // User's current timezone
    const [availableTimezones, setAvailableTimezones] = useState<string[]>(moment.tz.names()); // List of all timezones

    // on componenet load
    useEffect(() => {
        setMonth(moment().month())

        // fetch free slots for the next 3 months
        const fetchFreeSlots = async () => {
            const promises = [fetchPublicSchedule(moment().unix() * 1000, 'month', userName!),
            fetchPublicSchedule(moment().add(1, "month").unix() * 1000, 'month', userName!),
            fetchPublicSchedule(moment().add(2, "month").unix() * 1000, 'month', userName!)]

            const results = await Promise.all(promises)
            const freeSlotsForCurrentMonth = results[0]["freeSlotsByDate"]
            const freeSlotsForNextMonth = results[1]["freeSlotsByDate"]
            const freeSlotsForThirdMonth = results[2]["freeSlotsByDate"]

            setScheduleLoading(false)
            setFreeSlots({
                ...freeSlotsForCurrentMonth,
                ...freeSlotsForNextMonth,
                ...freeSlotsForThirdMonth
            })
        }


        if (userName) {
            fetchFreeSlots()
        }
    }, [])


    useEffect(() => {
        if (userName === "") {
            return;
        }

        const fetchUserData = async () => {
            const db = getFirebaseFirestore();
            try {
                // Create a query to find the user document by username
                const userQuery = query(
                    collection(db, SERENE_USERS), // The collection path
                    where('username', '==', userName) // Query by username
                );

                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    // If the query returns results, get the first document
                    const userDoc = querySnapshot.docs[0].data() as SereneUser;
                    setUserData(userDoc);
                } else {
                    setError('User not found');
                }
            } catch (err) {
                setError('Failed to fetch user data');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userName]);


    const handleTimezoneChange = (event: any) => {
        setTimezone(event.target.value);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }


    return (
        <>
            <Helmet>
                <title>{userData!["display_name"]} | Vybz Health</title>
                <meta name="description" content="Serene is a mental health platform for clinicians, empowering them to deliver outstanding care with intelligent, data-driven tools that enhance every aspect of mental health support." />
                {/* Open Graph Tags for Facebook, WhatsApp, and other platforms */}
                <meta property="og:title" content={`${userData!["display_name"]} | Vybz Health`} />
                <meta property="og:description" content="Serene is a mental health platform for clinicians, empowering them with data-driven tools for enhanced mental health support." />
                <meta property="og:url" content={`https://serene.vybz.health/user/${userName}`} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={userData!["display_picture"]} />
                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${userData!["display_name"]} | Vybz Health`} />
                <meta name="twitter:description" content="Serene is a platform empowering clinicians with intelligent tools for better mental health support." />
                <meta name="twitter:image" content={userData!["display_picture"]} />
                <meta name="twitter:url" content={`https://serene.vybz.health/user/${userName}`} />
                {/* WhatsApp and other Open Graph enabled platforms */}
                <meta property="og:site_name" content="Serene" />
            </Helmet>

            <Box className={publicPageStyle.container}>
                <Box display="flex" alignItems="center" mb={3} className={publicPageStyle.top_details}>
                    <Avatar
                        className={publicPageStyle.avatar}
                        alt={userData!.display_name}
                        src={userData!.display_picture}
                    />
                    <Box className={publicPageStyle.top_text}>
                        <Typography variant="h4" fontWeight={"bolder"} gutterBottom lineHeight={0.7}>
                            {userData!.display_name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Welcome 👋
                        </Typography>
                    </Box>
                </Box>
                <ChipsContainer chips={userData?.expertise ?? []} />
                <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                    <div className={publicPageStyle.title}>
                        <div className={publicPageStyle.booking}>
                            <Typography variant="h5" fontWeight={"bolder"}>Book A Session</Typography>
                            <Typography variant="body2" color={"grey"}>Looking forward to helping you on your path to better mental health</Typography>
                        </div>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            {/* Label positioned to the left of the select */}
                            <PublicIcon sx={{ mr: 1 }} className={publicPageStyle.tz_icon} />

                            {/* Select dropdown with GMT offsets */}
                            <FormControl fullWidth variant="outlined">
                                <Select value={timezone} onChange={handleTimezoneChange} sx={{ borderRadius: "20px" }}>
                                    {availableTimezones.map((tz) => {
                                        const offset = moment.tz(tz).utcOffset() / 60;
                                        const sign = offset >= 0 ? "+" : "-";
                                        const formattedTimezone = ` ${tz} (GMT ${sign} ${Math.abs(offset).toString().padStart(2, '0')}:00)`;
                                        return (
                                            <MenuItem key={tz} value={tz}>
                                                {formattedTimezone}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                <Scheduler
                    loading={scheduleLoading}
                    freeSlots={freeSlots}
                    availability={userData?.availability ?? {}}
                    userTimezone={userData?.timezone ?? ""}
                    scheduleTimezone={timezone}
                    userId={userData?.id ?? ""}
                    dateChangeCallback={(newDate: Date) => {
                        setMonth(newDate.getMonth());
                        setDate(moment(newDate).format("YYYY-MM-DD"))
                    }} />
                <br />
                <div className={publicPageStyle.title}>
                    <Typography variant="h5" fontWeight={"bolder"}>About Me</Typography>
                </div>
                <div className={publicPageStyle.about}>
                    <Typography variant="body1" color={"text.secondary"}>{userData?.about}</Typography>

                </div>

                <br />
                <div className={publicPageStyle.title}>
                    <Typography variant="h5" fontWeight={"bolder"}>Languages</Typography>
                </div>
                <ChipsContainer chips={userData?.languages ?? []} />

            </Box>
        </>
    );
};


const ChipsContainer = ({ chips }: { chips: string[] }) => {
    const chipsRef = useRef(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const handleMouseDown = (e: any) => {
        isDragging.current = true;
        //@ts-ignore
        startX.current = e.pageX - chipsRef.current.offsetLeft;
        //@ts-ignore
        scrollLeft.current = chipsRef.current.scrollLeft;
    };

    //@ts-ignore
    const handleMouseMove = (e) => {
        if (!isDragging.current) return;
        e.preventDefault();
        //@ts-ignore
        const x = e.pageX - chipsRef.current.offsetLeft;
        const walk = (x - startX.current) * 2; // scroll faster by multiplying
        //@ts-ignore
        chipsRef.current.scrollLeft = scrollLeft.current - walk;
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    return (
        <div
            className={publicPageStyle.chips}
            ref={chipsRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp} // stop dragging when mouse leaves
        >
            {chips?.map((data, index) => (
                <Chip key={index} label={data} sx={{ marginRight: "5px", marginLeft: "5px", cursor: "pointer" }} />
            ))}
        </div>
    );
};
