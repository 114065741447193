import { Typography } from "@mui/material";
import Header from "../../components/header";
import aboutStyles from "./style/about.module.css"
import anurag from "../../assets/anurag.jpg"
import priyanka from "../../assets/Priyanka.jpg"
import picklu from "../../assets/picklu.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faResearchgate, faLinkedin, faGoogleScholar } from '@fortawesome/free-brands-svg-icons';

export const About = ({ product }: { product: string }) => {
    return (
        <div >
            <Header product={product} />
            <div className={aboutStyles.container}>
                <Typography variant="h3" fontWeight={"bolder"}>🤔 Who Are We? </Typography>
                <br />
                <div className={aboutStyles.intro_container}>
                    <Typography variant="body1" fontSize={"18px"} color={"text.secondary"}>
                        We are a team of researchers, doctors, and engineers passionate about solving the many challenges in mental health care. With our combined skills in clinical practice, technology, and research, we believe that advancements in tech can transform how mental health is supported, making care more accessible and effective. Our goal is simple: to prevent future mental health crises and ensure that everyone has the tools to maintain their well-being. By using innovative solutions, we are committed to making a lasting, positive impact on people’s mental health around the world.
                    </Typography>
                </div>
                <br />
                <br />
                <Typography variant="h3" fontWeight={"bolder"}>🎯 Our Mission! </Typography>
                <br />
                <div className={aboutStyles.intro_container}>
                    <Typography variant="body1" fontSize={"18px"} color={"text.secondary"}>
                        Our mission is to elevate mental health to the same priority as physical health. We offer simple, effective tools that help people care for their mental well-being in their everyday lives. By harnessing advanced technology, we empower individuals to take control of their mental health, while also supporting innovators and professionals working to solve the biggest challenges in mental health care. We strive to create a future where no one’s mental health goes unnoticed or untreated.
                    </Typography>
                </div>
                <br />
                <br />
                <Typography variant="h3" fontWeight={"bolder"}>👋 Meet The Team! </Typography>
                <br />
                <div className={aboutStyles.team_member} >
                    <img src={anurag} className={aboutStyles.team_img} />
                    <div className={aboutStyles.intro_container}>
                        <div className={aboutStyles.name_row}>
                            <Typography variant="h5" color={"text.secondary"} fontWeight={"bolder"}>Anurag Mandal</Typography>
                            <FontAwesomeIcon icon={faLinkedin} size="xl" color="grey"
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                onClick={() => { window.open("https://www.linkedin.com/in/anurag-mandal-86a65b164/", '_blank')?.focus() }} />

                            <FontAwesomeIcon icon={faGoogleScholar} size="xl" color="grey"
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                onClick={() => { window.open("https://scholar.google.com/citations?user=4n9Rj5wAAAAJ&hl=en", '_blank')?.focus() }} />


                        </div>

                        <Typography variant="body1" color={"text.secondary"}>
                            Anurag is an experienced software developer who has worked at Amazon, where he specialized in full stack development. Over the course of his career, he has also developed strong expertise in AI and machine learning, refining these skills while completing his master’s at New York University. It was during his time at NYU, working with the fire research department, that Anurag interviewed nearly 100 firefighters and uncovered the daily mental health challenges they face. This experience sparked his passion for using technology to address mental health issues. Since then, Anurag has been committed to research aimed at leveraging tech to improve mental health care and make a lasting impact in the field.
                        </Typography>
                    </div>
                </div>
                <br />
                <div className={aboutStyles.team_member}>
                    <img src={priyanka} className={aboutStyles.team_img} />
                    <div className={aboutStyles.intro_container}>
                        <div className={aboutStyles.name_row}>
                            <Typography variant="h5" color={"text.secondary"} fontWeight={"bolder"}>Dr. Priyanka Podder</Typography>
                            <FontAwesomeIcon icon={faLinkedin} size="xl" color="grey"
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                onClick={() => { window.open("https://www.linkedin.com/in/priyanka-podder-456225b9/?originalSubdomain=in", '_blank')?.focus() }} />
                            {/* <FontAwesomeIcon icon={faResearchgate} size="xl" color="grey"
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                onClick={() => { window.open("https://www.researchgate.net/profile/Picklu-Chaudhuri", '_blank')?.focus() }} /> */}
                        </div>
                        <Typography variant="body1" color={"text.secondary"}>
                            Dr. Priyanka Podder is a licensed Clinical Psychologist with an M.Sc. in Psychology, an M.Phil. in Clinical Psychology, and a Ph.D. in Psychology from the University of Calcutta. With over a decade of professional experience, she has held diverse roles as a Clinical Psychologist, Research Fellow, and Guest Faculty. Currently, she serves as a Consultant Clinical Psychologist and Assistant Professor of Psychology. Her expertise spans psychometric assessments, psychotherapy, and working with children, adolescents, and families. She specializes in trauma, abuse, and gender-related issues, with extensive experience in community service. Dr. Podder is also an active researcher, having published articles in peer-reviewed journals in the fields of clinical and forensic psychology.
                        </Typography>
                    </div>
                </div>
                <br />
                <div className={aboutStyles.team_member}>
                    <img src={picklu} className={aboutStyles.team_img} />
                    <div className={aboutStyles.intro_container}>
                        <div className={aboutStyles.name_row}>
                            <Typography variant="h5" color={"text.secondary"} fontWeight={"bolder"}>Professor Dr. Picklu Chaudhuri</Typography>
                            <FontAwesomeIcon icon={faResearchgate} size="xl" color="grey"
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                onClick={() => { window.open("https://www.researchgate.net/profile/Picklu-Chaudhuri", '_blank')?.focus() }} />
                        </div>
                        <Typography variant="body1" color={"text.secondary"}>
                            Dr. Picklu Chaudhuri is a highly experienced gynecologist with over 21 years in the field. Throughout her career, she has contributed to medical research, publishing original articles in 10 international and 20 national journals. Having witnessed the profound effects of postpartum depression on new mothers, Dr. Chaudhuri is deeply motivated to improve mental health care, particularly for young mothers. Her dedication to addressing this critical issue drives her ongoing efforts to enhance mental health support for women during and after pregnancy.
                        </Typography>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <Typography variant="body1" color={"text.secondary"} fontWeight={"normal"}>We are growing. If you're passionate about making a difference in mental health, we’d love to hear from you. Send us a message!</Typography>
            </div>
        </div>
    );
}