import { Button, Divider, Typography } from "@mui/material";
import landingpageStyle from "./style/section-three.module.css";
import StressPreview from "../../../assets/Stress.png";
import SleepPreview from "../../../assets/sleep.png";
import MoodPreview from "../../../assets/Mood.png";
import Hoopy from "../../../assets/hoopy.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const SectionThree = () => {

    const navigate = useNavigate();

    return (
        <div className={landingpageStyle.third_section} >
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h3" fontWeight={"bolder"} align="left">Everything you need to live a happy life 😎</Typography>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h5" color="text.secondary" align="left" sx={{ marginLeft: "2px" }}>Right in your pocket</Typography>
            </motion.div>
            <div className={landingpageStyle.spacer_vertical} />
            <Preview
                onButtonClick={() => { navigate("/stress") }}
                useSpacer={false}
                buttonText="How do I reduce stress?"
                title="🤒 Stress Tracking"
                image={StressPreview}
                description={<Typography variant="body1" align="left">
                    <section id="stress-tracking">
                        <p>Experience unparalleled insight into your mental well-being with Vybz's advanced stress tracking.</p>
                        <br />
                        <br />
                        <ul>
                            <li>✅ Detect stress in <strong>Real-Time</strong></li>
                            <li>✅<strong> Recognize patterns</strong> in your stress levels</li>
                            <li>✅ Receive <strong>personalized tips</strong> to manage and reduce stress effectively</li>
                            <li>✅<strong> Enhance your overall health</strong> and enjoy better sleep</li>
                            <li>✅ Lower your blood pressure and <strong>increase productivity</strong></li>
                        </ul>
                    </section>
                </Typography>} />

            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />
            <Preview
                onButtonClick={() => { navigate("/building") }}
                useSpacer
                buttonText="How does Mood tracking work?"
                image={MoodPreview}
                title="📋 Mood Reports"
                description={<Typography variant="body1"
                    align="left">
                    <section id="mood-tracking">
                        <p>Unlock a deeper understanding of your emotional landscape with Vybz's mood monitor.</p>
                        <br />
                        <br />
                        <ul>
                            <li>✅ Monitor daily mood variations and <strong>identify triggers</strong></li>
                            <li>✅<strong> Track</strong> emotional patterns over time</li>
                            <li>✅ Receive <strong>tailored advice</strong> to improve your mood and well-being</li>
                            <li>✅ Identify factors <strong>influencing your happiness</strong></li>
                            <li>✅ Enhance <strong>self-awareness</strong> and emotional resilience</li>
                        </ul>
                        <br />
                        <p>Empower yourself with the tools to achieve emotional balance and well-being.</p>
                    </section>
                </Typography>} />
            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />
            <Preview
                onButtonClick={() => { navigate("/building") }}
                useSpacer={false}
                buttonText="Unlock Peaceful Sleep!"
                title="😴 Sleep Insights"
                image={SleepPreview}
                description={<Typography variant="body1" align="left">
                    <section id="sleep-tracking">
                        <p>Optimize your rest and recovery with Vybz's comprehensive sleep reports.</p>
                        <br />
                        <br />
                        <ul>
                            <li>✅ Monitor your sleep <strong>patterns and quality</strong></li>
                            <li>✅ Identify <strong>disruptions</strong> and their <strong>causes</strong></li>
                            <li>✅ Understand the <strong>connection</strong> between sleep and overall wellness</li>
                            <li>✅ Boost your <strong>energy levels</strong> and cognitive function</li>
                        </ul>
                        <br />
                        <p>Achieve better sleep and wake up feeling refreshed and rejuvenated.</p>
                    </section>
                </Typography>} />
            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />
            <Preview
                onButtonClick={() => { navigate("/building") }}
                useSpacer={true}
                buttonText="Talk to Hoopy!"
                title="🦋 Hoopy!"
                image={Hoopy}
                description={<Typography variant="body1" align="left">
                    <section id="sleep-tracking">
                        <p>Your <strong>24 X 7</strong> mental wellness companion</p>
                        <br />
                        <br />
                        <ul>
                            <li>✅ Connect with Hoopy, your personal mental wellness companion, <strong>at any time of the day</strong>. Hoopy is always there to listen and provide support.</li>
                            <li>✅<strong> By tapping into your data, Hoopy offers tailored advice</strong> to help you navigate life's challenges and maintain a positive mindset.</li>
                            <li>✅ Whether you’re dealing with stress, anxiety, or just need someone to talk to, <strong>Hoopy is here to help</strong> you live a happier, healthier life.</li>
                        </ul>
                        <br />
                        <p>With Hoopy by your side, you're never alone in your journey towards mental wellness. Embrace a happier life with personalized support and guidance every step of the way.</p>
                    </section>
                </Typography>} />
            <div className={landingpageStyle.spacer_vertical} />
            <Typography variant="h4" fontWeight={"bolder"} align="left">And More ...</Typography>
        </div>)


}

interface Props {
    useSpacer: boolean;
    image: string;
    title: string
    description: any,
    buttonText: string,
    onButtonClick: null | any
}

const Preview: React.FC<Props> = ({ useSpacer, image,
    description, title, buttonText,
    onButtonClick = () => { } }) => {

    return <div className={landingpageStyle.preview_row}>
        {useSpacer ? <div className={landingpageStyle.spacer} /> : null}
        <motion.img
            initial={{ opacity: 0, }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={landingpageStyle.stress_preview} src={image} />
        <br />
        <br />
        <br />
        <motion.div className={landingpageStyle.preview_description}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}>
            <Typography variant="h3" fontWeight="bolder" align="left">{title}</Typography>
            <br />
            <br />
            {description}
            <br />
            <br />
            <Button disableFocusRipple disableRipple disableTouchRipple color="primary"
                variant="outlined"
                onClick={onButtonClick}
                sx={{ borderRadius: 30, padding: 1, minWidth: "200px" }}>{buttonText}</Button>
        </motion.div>
    </div >;
}

export default SectionThree;