import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, IconButton, List, Typography, Skeleton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getFunctions, httpsCallable } from 'firebase/functions';
import calenderStyle from "../style/calender.module.css";
import moment from 'moment';
import { formatTimeAgo } from '../../../helper/time-helper';
import { BookingData } from '../../../types';
import { useNavigate } from 'react-router-dom';

interface FetchBookingsResponse {
    events: BookingData[];
    nextPageToken: string | null;
}

interface Cache {
    [pageToken: string]: FetchBookingsResponse;
}

// Initialize Firebase Functions
const functions = getFunctions();

export const PastBookings: React.FC = () => {
    const [bookings, setBookings] = useState<BookingData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageToken, setPageToken] = useState<string | null>(null);
    const [nextPageToken, setNextPageToken] = useState<string | null>(null);
    const [prevTokens, setPrevTokens] = useState<string[]>([]); // Stack to handle previous page tokens
    const [cache, setCache] = useState<Cache>({}); // Cache object for storing paginated data

    const navigate = useNavigate();

    // Callable function for fetching past bookings
    const fetchPastBookings = async (pageToken: string | null): Promise<FetchBookingsResponse> => {
        const getPastBookings = httpsCallable(functions, 'getPastBookings');
        try {
            const result = await getPastBookings({ pageToken });
            return result.data as FetchBookingsResponse;
        } catch (error) {
            console.error("Error fetching past bookings:", error);
            return { events: [], nextPageToken: null };
        }
    };

    const loadBookings = async (token: string | null = null) => {
        setLoading(true);

        // Check if data for this pageToken is already cached
        if (token && cache[token]) {
            const cachedData = cache[token];
            setBookings(cachedData.events);
            setNextPageToken(cachedData.nextPageToken);
            setLoading(false);
            return;
        }

        // Fetch data from Firebase function if not cached
        const data = await fetchPastBookings(token);

        // Update state and cache the result
        setBookings(data.events);
        setNextPageToken(data.nextPageToken || null);
        setCache((prevCache) => ({
            ...prevCache,
            [token || '']: data, // Cache the data with pageToken as the key
        }));
        setLoading(false);
    };

    useEffect(() => {
        loadBookings();
    }, []);

    const handleNext = () => {
        if (nextPageToken) {
            setPrevTokens((tokens) => [...tokens, pageToken || '']);
            setPageToken(nextPageToken);
            loadBookings(nextPageToken);
        }
    };

    const handlePrevious = () => {
        const newTokens = [...prevTokens];
        const prevToken = newTokens.pop();
        setPrevTokens(newTokens);
        setPageToken(prevToken || null);
        loadBookings(prevToken || null);
    };

    return (
        <div>
            {loading ? (
                // Render shimmering skeletons while loading
                <List>
                    {[...Array(3)].map((_, index) => (
                        <div className={calenderStyle.item_container} key={index}>
                            <Box display="flex" flexDirection="column" flex="1">
                                <Skeleton variant="text" width="20%" height={30} />
                                <Skeleton variant="text" width="10%" height={20} />
                                <Skeleton variant="text" width="10%" height={15} />
                            </Box>
                            <Box display="flex" flexDirection="column" width="150px">
                                <Skeleton variant="rectangular" height={40} width="100%" style={{ borderRadius: '20px' }} />
                            </Box>
                        </div>
                    ))}
                </List>
            ) : bookings.length === 0 ? (
                <Typography variant="body1">No bookings found.</Typography>
            ) : (
                <List>
                    {bookings.map((event) => {
                        const bookingId = event.extendedProperties?.private?.bookingId ?? "";
                        return (
                            <div className={calenderStyle.item_container} key={bookingId}>
                                <div>
                                    <Typography variant="h5" fontWeight={"bolder"}>{event?.extendedProperties?.private?.name ?? "John Doe"}</Typography>
                                    <Typography variant="body2">{moment(event.start.dateTime).format("Do MMM HH:mm A")}</Typography>
                                    <Typography variant="body2" fontSize={12} color={"grey"}>{formatTimeAgo(event.start.dateTime)}</Typography>
                                </div>
                                <Box display={"flex"} flexGrow={1} />
                                <Box flexDirection={"column"} display={"flex"} width={"150px"}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        sx={{
                                            borderRadius: "20px"
                                        }}
                                        fullWidth
                                        onClick={() => navigate(`/booking/${bookingId}`)}
                                    >
                                        Details
                                    </Button>
                                </Box>
                            </div>
                        );
                    })}
                </List>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <IconButton
                    onClick={handlePrevious}
                    disabled={prevTokens.length === 0 || loading}
                    color="primary"
                    size="large"
                    style={{
                        backgroundColor: prevTokens.length === 0 || loading ? '#E0E0E0' : '#1976d2',
                        color: 'white'
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>

                <IconButton
                    onClick={handleNext}
                    disabled={!nextPageToken || loading}
                    color="primary"
                    size="large"
                    style={{
                        backgroundColor: !nextPageToken || loading ? '#E0E0E0' : '#1976d2',
                        color: 'white'
                    }}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </div>
            <br />
            <br />
        </div>
    );
};
