import React, { useState } from "react";
import { Box, Typography, Button, Snackbar, Alert, Skeleton, CircularProgress } from "@mui/material";
import Calendar from "react-calendar";
import publicPageStyle from "./style/public-page.module.css";
import 'react-calendar/dist/Calendar.css';
import { IconButton } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { v4 as uuidv4 } from 'uuid';
import { Availability, Slot } from "../../../types";
import BookingConfirm from "./booking-confirm";
import { getFirebaseFirestore } from "../../../const";
import { doc, setDoc } from "@firebase/firestore";
import moment from 'moment-timezone';
import { calculateIntersectionForDate } from "../../../helper/time-helper";
import { Verification } from "../../../components/verification";
import { getFunctions, httpsCallable } from "firebase/functions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import completeAnimation from '../../../assets/complete_animation.json';
import Lottie from "lottie-react";


// Function to convert time between timezones using moment-timezone
const convertTimeBetweenTimezones = (timeString: string, fromTimezone: string, toTimezone: string) => {
    // Convert the timeString from the source timezone to the destination timezone
    const convertedTime = moment.tz(timeString, 'HH:mm', fromTimezone).tz(toTimezone).format('HH:mm');
    return convertedTime;
};

const Scheduler = ({ freeSlots, availability, userId, dateChangeCallback, userTimezone, scheduleTimezone, loading }:
    {
        loading: boolean
        freeSlots: any,
        availability: Availability,
        userId: string,
        dateChangeCallback: any,
        userTimezone: string,
        scheduleTimezone: string
    }) => {
    const [date, setDate] = useState(new Date());
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedTime, setSelectedTime] = useState("");
    const [timeSlots, setTimeSlots] = useState<any[]>([]);
    const [bookingDetails, setBookingDetails] = useState<{
        name: string, phone: string,
        email: string, bookingId: string,
        agreeUpdates: boolean

    }>({
        name: "",
        phone: "",
        email: "",
        bookingId: "",
        agreeUpdates: true
    })
    const [step, setStep] = useState(0);



    const onDateChange = (newDate: Date) => {
        setDate(newDate);
        setStep(0)
        setSelectedTime("");

        const selectedDaySlots = calculateIntersectionForDate(availability, freeSlots, moment(newDate).format("YYYY-MM-DD"))

        const expandedSlots = selectedDaySlots.flatMap((slot: any) => {
            const startHour = parseInt(slot.start_time.split(':')[0]);
            const endHour = parseInt(slot.end_time.split(':')[0]);

            // Generate hourly intervals from start_time to end_time
            const hourlySlots = [];
            for (let hour = startHour; hour < endHour; hour++) {
                const formattedStart = `${hour.toString().padStart(2, '0')}:00`;
                hourlySlots.push({ start_time: formattedStart });
            }
            return hourlySlots;
        });

        // Update the timeSlots state with the available slots for that day
        setTimeSlots(expandedSlots);
        dateChangeCallback(newDate)
    };

    const handleBooking = (slot: { start_time: string; }) => {
        setSelectedTime(slot.start_time)
        //alert(`Booking confirmed for ${slot.start_time} - ${slot.end_time}`);
    };

    /**
     * 
     */
    const sendOTPToNumber = async (phone: string, bookingId: string) => {
        // send the confirmation code to the user
        const sendConfirmationCode = httpsCallable(getFunctions(), 'sendConfirmationCode');
        await sendConfirmationCode({
            phoneNumber: phone,
            bookingId: phone
        })
    }

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000} // Automatically hide after 3 seconds
                onClose={() => { setOpenSnackbar(false) }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position at the top-right
            >
                <Alert onClose={() => { setOpenSnackbar(false) }} severity={"success"} sx={{ width: '100%' }}>
                    Booking confirmed!
                </Alert>
            </Snackbar>
            <div className={publicPageStyle.scheduler}>
                <div className={publicPageStyle.calender}>
                    {/*@ts-ignore */}
                    <Calendar onChange={onDateChange}
                        prev2Label={null}
                        next2Label={null}
                        value={date}
                        minDate={moment().startOf("D").toDate()}
                        maxDate={moment().add(2, "months").toDate()}
                    />
                </div>
                <div className={publicPageStyle.time_slots}>
                    <div className={publicPageStyle.time_slots_title}>
                        <div className={publicPageStyle.time_slots_title_row}>
                            <div className={publicPageStyle.left}>
                                {step != 3 && <div onClick={step > 0 ? () => { setStep(step - 1) } : () => { }}>
                                    <ArrowBackIcon sx={{ cursor: "pointer", opacity: step == 0 ? 0.2 : 1 }} />
                                </div>}
                            </div>
                            <Typography fontSize={"20px"} fontFamily={"Roboto"} fontWeight={"bolder"}
                                textAlign={"center"}
                                flex={3}
                                whiteSpace={"nowrap"}>
                                {step == 0 && "Availability"}
                                {step == 1 && "Enter Your Details"}
                                {step == 2 && "Enter OTP"}
                                {step == 3 && "Booking confirmed!"}
                            </Typography>
                            <div className={publicPageStyle.right} />
                        </div>
                    </div>
                    {step == 0 &&
                        <Slots
                            loading={loading}
                            userTimezone={userTimezone}
                            scheduleTimezone={scheduleTimezone}
                            timeSlots={timeSlots}
                            selectedTime={selectedTime}
                            handleBooking={handleBooking}
                            onConfirm={() => { setStep(1) }} />
                    }
                    {step == 1 &&
                        <BookingConfirm onConfirm={async (name: string, email: string, phone: string, agreeUpdates: boolean) => {

                            const bookingId = uuidv4()

                            setBookingDetails({
                                name: name,
                                email: email,
                                phone: phone,
                                bookingId: bookingId,
                                agreeUpdates: agreeUpdates
                            })
                            setStep(2)

                            await sendOTPToNumber(phone, bookingId)


                        }} />
                    }
                    {step == 2 &&
                        <div className={publicPageStyle.time_slots_bookings}>
                            <Typography color={"text.secondary"} fontSize={12}>A text message with an OTP has been sent to {bookingDetails.phone}. Please enter the code below to confirm your booking.</Typography>
                            <br />
                            <Verification onConfirm={async (code: string) => {
                                const db = getFirebaseFirestore();
                                const bookingRef = doc(db, "booking", bookingDetails.bookingId);
                                // set the booking
                                await setDoc(bookingRef, {
                                    id: bookingDetails.bookingId,
                                    name: bookingDetails.name,
                                    email: bookingDetails.email,
                                    phone: bookingDetails.phone,
                                    user: userId,
                                    date: moment(date).unix() * 1000,
                                    time: selectedTime,
                                    timezone: scheduleTimezone,
                                    agree_updates: bookingDetails.agreeUpdates
                                });
                                setOpenSnackbar(true)
                                setSelectedTime("");
                                setStep(3)
                            }} />
                            <br />
                            <Typography variant="body2" fontSize={12}>Did not receive your OTP?<span className={publicPageStyle.resend} onClick={async () => {
                                await sendOTPToNumber(bookingDetails.phone, bookingDetails.bookingId)
                            }}>Resend code</span></Typography>
                            <Typography variant="body2" fontSize={12}>Send code via email<span className={publicPageStyle.resend}>{bookingDetails.email}</span></Typography>
                            {/* <Typography variant="body2" fontSize={12}>Or</Typography>
                            <Typography variant="body2" fontSize={12}>Verify via {bookingDetails.email}</Typography> */}
                        </div>}
                    {step == 3 && <div className={publicPageStyle.time_slots_bookings}>
                        <Typography color={"text.secondary"} fontSize={12}>Your session is scheduled on {moment(date).format("MMM Do")}. You will receive the details about your session on your email.</Typography>
                        <br />
                        <Lottie animationData={completeAnimation} style={{ height: "150px" }} loop={false} />
                    </div>}
                </div>
            </div>
        </>
    );
};

const Slots = ({ timeSlots, selectedTime, handleBooking, userTimezone, scheduleTimezone, onConfirm, loading }:
    {
        timeSlots: any[], selectedTime: string, handleBooking: any,
        userTimezone: string, scheduleTimezone: string, onConfirm: any, loading: boolean
    }) => {

    return loading ? <div className={publicPageStyle.no_slots}>
        <CircularProgress sx={{ color: "black" }} />
    </div> : <div className={publicPageStyle.time_slots_bookings}>
        {timeSlots.length > 0 ? (
            timeSlots.map((slot, index) => (

                <div className={publicPageStyle.slot_container} key={index}>
                    <div className={publicPageStyle.slot_button}>
                        <Button
                            disableElevation
                            variant={selectedTime == slot.start_time ? "contained" : "outlined"}
                            onClick={() => handleBooking(slot)}
                            sx={{
                                opacity: selectedTime == "" ? 1 : selectedTime == slot.start_time ? 1 : 0.3,
                                transition: "all 500ms",
                                border: "0.1px solid grey",
                                width: selectedTime == slot.start_time ? "80%" : "100%",
                                boxSizing: "border-box",
                                borderRadius: "10px",
                                color: selectedTime == slot.start_time ? 'white' : "grey",
                                padding: "10px",
                                '&:hover': {
                                    border: "0.1px solid black",
                                    backgroundColor: selectedTime == slot.start_time ? 'black' : "white", // Disable hover effect
                                    opacity: 1, // Keep the opacity the same,
                                },
                                backgroundColor: selectedTime == slot.start_time ? 'black' : "white"
                            }}
                        >
                            {`${convertTimeBetweenTimezones(slot.start_time, userTimezone, scheduleTimezone)}`}
                        </Button>
                        <div style={{
                            overflow: "hidden", maxWidth: selectedTime == slot.start_time ? "1000px" : "0px", flex: 1, display: "flex",
                            marginLeft: selectedTime == slot.start_time ? "5px" : "0px"
                        }}>
                            <IconButton
                                onClick={onConfirm}
                                sx={{
                                    borderRadius: "100%",
                                    // border: "0.1px solid grey",
                                    flex: 1,
                                    color: "grey",
                                    '&:hover': {
                                        backgroundColor: "transparent", // Optional, keeps it minimal
                                        color: "black", // Change color on hover if needed
                                    },
                                }}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className={publicPageStyle.no_slots}>
                <Typography>No available time slots for this date.</Typography>
            </div>
        )}
    </div>;
}

export default Scheduler;
