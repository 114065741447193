import { useEffect, useState } from "react";
import { AppState, ClientDetails, NoteDetails } from "../../../types";
import { doc, getDoc, collection, query, orderBy, getDocs, updateDoc, onSnapshot } from "firebase/firestore";
import { getFirebaseFirestore } from "../../../const";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, DialogActions, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CancelIcon from '@mui/icons-material/Cancel';
import MailIcon from '@mui/icons-material/Mail';
import clientHistoryStyle from "./style/client-history.module.css"
import moment from "moment";
import { ProtectedRoute } from "../../../components/protected-route";
import BdiProgress from "./bid-progress";
import { Modal, TextInput } from 'flowbite-react';
import { Edit } from "../../../components/edit";

export const ClientHistory = ({ }) => {

    const params = useParams()
    const user = useSelector((state: AppState) => state.user)
    const [email, setEmail] = useState("");
    const [editEmail, setEditEmail] = useState(false);
    const [clientDetails, setClientDetails] = useState<ClientDetails | null>(null);
    const [notes, setNotes] = useState<NoteDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const db = getFirebaseFirestore()
    const clientId = params["clientid"];
    const navigate = useNavigate()


    useEffect(() => {
        if (user == null || !user.fetched) {
            return;
        }

        var snapshot: any = null;

        const fetchClientDetailsAndNotes = async () => {
            setLoading(true);
            try {
                // Fetch client details
                const clientDocRef = doc(db, `/serene_users/${user.id}/client_records/${clientId}`);
                snapshot = onSnapshot(clientDocRef, (clientSnapshot) => {
                    if (clientSnapshot.exists()) {
                        setClientDetails(clientSnapshot.data() as ClientDetails);
                    }
                })





                // Fetch notes associated with the client, sorted by timestamp
                const notesCollectionRef = collection(db, `/serene_users/${user.id}/client_records/${clientId}/client_notes`);
                const notesQuery = query(notesCollectionRef, orderBy('timestamp', 'desc'));
                const notesSnapshot = await getDocs(notesQuery);

                const notesList: NoteDetails[] = notesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as NoteDetails[];

                setNotes(notesList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching client details or notes: ", error);
            }
        };

        fetchClientDetailsAndNotes();

        return () => {
            if (snapshot) {
                snapshot()
            }

        }

    }, [user, clientId]);

    const bdi = notes.map((n, i) => {
        return {
            time: n.timestamp,
            value: Object.values(n?.bdi_score ?? { total: 0 }).reduce((p, c) => p + c, 0)
        }
    })

    return (
        <ProtectedRoute>

            {!loading ? <div className={clientHistoryStyle.container}>
                <Modal show={editEmail} onClose={() => { setEditEmail(false) }}>
                    <Modal.Header >
                        <Typography variant='h5' fontWeight={"bolder"}>Edit Email</Typography>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <Typography variant='body1' color={"text.secondary"}>Email is used for any communication between you and your client.</Typography>
                        </div>

                        <br />
                        <TextInput aria-label="email" type="email" placeholder="JohnDoe@gmail.com" style={{ height: 50 }}
                            onChange={(e) => {
                                const value = e.target.value;
                                setEmail(value);
                            }} />


                        {/* <div className={dahsboardStyle.center_progress}>
                        <CircularProgress value={uploadProgress} size={50} thickness={4} variant='determinate' />
                    </div> */}
                    </Modal.Body>

                    <DialogActions>
                        <Button sx={{ borderRadius: "5px", marginRight: "20px" }}
                            onClick={() => {
                                setEditEmail(false)
                            }}>
                            Cancel
                        </Button>
                        <Button sx={{ borderRadius: "5px", marginRight: "20px" }}
                            onClick={async () => {
                                const clientDocRef = doc(db, `/serene_users/${user.id}/client_records/${clientId}`);
                                await updateDoc(clientDocRef, {
                                    email: email
                                });
                                setEditEmail(false)
                            }}>
                            Save
                        </Button>
                    </DialogActions>
                    <br />

                </Modal>
                {clientDetails && (
                    <div className={clientHistoryStyle.client_details}>
                        <div className={clientHistoryStyle.header}>
                            <Typography variant="h3" fontWeight={"bolder"}>{clientDetails.client_name}</Typography>
                            <div className={clientHistoryStyle.header_actions}>
                                {/* <div onClick={() => navigate(-1)}>
                                    <MailIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px", color: "#D33E43" }} />
                                </div> */}
                                <div onClick={() => navigate(-1)}>
                                    <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={clientHistoryStyle.email_edit}>
                            <Typography variant="h5" fontWeight={"bolder"}><span className={clientHistoryStyle.email}>{!clientDetails.email ? "Email Not assigned" : clientDetails.email}</span></Typography>
                            <Edit onClick={() => { setEditEmail(true) }} />
                        </div>
                        {/* <Typography variant="body1" color={"grey"}>🕓 Created on {moment(clientDetails.creation_timestamp || 0).format("MMM Do hh:mm A")}</Typography> */}
                        <Typography variant="body1" color={"grey"}>Last updated on {moment(clientDetails.last_update || 0).format("MMM Do hh:mm A")}</Typography>
                    </div>
                )}


                <div className={clientHistoryStyle.client_notes}>
                    <Typography variant="h4" fontWeight={"bolder"}>📈 Progress</Typography>
                    <br />
                    <div className={clientHistoryStyle.progress_secition}>
                        <div className={clientHistoryStyle.chart_container}>
                            <Typography variant="body2">Beck Depression Inventory Scores</Typography>
                            <br />
                            <BdiProgress data={bdi} />
                        </div>
                        {/* <div className={clientHistoryStyle.spacer} />
                        <div className={clientHistoryStyle.chart_container_right}>
                            <Typography variant="body2">Beck Depression Inventory Scores</Typography>
                            <br />
                            <BdiProgress data={bdi} />
                        </div> */}
                    </div>
                </div>

                {/* Bottom part: List of Notes */}
                <div className={clientHistoryStyle.client_notes}>
                    <Typography variant="h4" fontWeight={"bolder"}>📑 Records ({notes.length})</Typography>
                    <br />
                    {notes.length > 0 ? (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {notes.map((note) => (
                                <li key={note.id} className={clientHistoryStyle.list_item} onClick={() => { navigate(`/note/${note?.id}`) }}>
                                    <div className={clientHistoryStyle.list_body}>
                                        <div>
                                            <p>🕓 {moment(note.timestamp).format("MMM Do hh:mm A")}</p>
                                            <div className={note.pending ? clientHistoryStyle.pending : clientHistoryStyle.approved}>
                                                {note.pending ? "Pending" : "Approved"}
                                            </div>
                                        </div>
                                        <NavigateNextIcon sx={{ fontSize: "40px" }} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No notes are available</p>
                    )}
                </div>
                <br />
                <br />
            </div> : <div className={clientHistoryStyle.container_loading}><CircularProgress /></div>}
        </ProtectedRoute>
    );
}