import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextareaAutosize
} from '@mui/material';

interface AboutDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    aboutText: string;
    setAboutText: (text: string) => void;
}

const AboutDialog: React.FC<AboutDialogProps> = ({
    open,
    onClose,
    onSave,
    aboutText,
    setAboutText
}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit About Me</DialogTitle>
            <DialogContent>
                <TextareaAutosize
                    id="about"
                    placeholder="Tell the world how amazing you are"
                    value={aboutText}
                    onChange={(e) => setAboutText(e.target.value)}
                    minRows={6}
                    style={{ width: '100%', resize: 'none' }}
                    aria-label="About Me"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} sx={{ borderRadius: "5px", marginRight: "20px" }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AboutDialog;
