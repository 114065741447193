import { Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppState, ClientDetails } from "../../../types";
import clientPageStyle from "./style/client.module.css"
import moment from "moment";
import { formatTimeAgo } from "../../../helper/time-helper";
import { useNavigate } from "react-router-dom";

export const Clients = () => {

    const [selectedChip, setSelectedChip] = useState<string>("Frequent");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const userClients = useSelector((state: AppState) => state.clients);
    const navigate = useNavigate();


    const handleChipClick = (chip: string) => {
        setSelectedChip(chip);
    };

    const getDescription = (chip: string) => {
        if (searchQuery.length > 0) {
            return <Typography>Search results for <span style={{ color: "black", fontWeight: "bolder" }}>"{searchQuery}"</span></Typography>;
        }
        switch (chip) {
            case "Frequent":
                return "Clients with regular sessions over the last 3 months";
            case "Recent":
                return "Newly onboarded clients";
            case "Requires Follow-ups":
                return "Clients needing follow-ups due to missed or irregular sessions";
            case "Archived":
                return "Clients with past sessions, saved for reference";
            case "All":
                return "All Your clients";
            default:
                return "";
        }
    };

    const getFilteredClients = (chip: string) => {
        switch (chip) {
            case "Frequent":
                return getFrequentClients();
            case "Recent":
                return getNewClients(userClients.clients);
            case "Requires Follow-ups":
                return getFollowUps(userClients.clients);
            case "Archived":
                return [];
            case "All":
                return userClients.clients;
            default:
                return [];
        }
    }

    const getNoTitle = (chip: string) => {
        switch (chip) {
            case "Frequent":
                return "No Frequent Clients";
            case "Recent":
                return "No New Clients";
            case "Requires Follow-ups":
                return "No Records";
            case "Archived":
                return "No Archieved Clients";
            case "All":
                return "No Records";
            default:
                return "";
        }
    }

    const getNoSubTitle = (chip: string) => {
        switch (chip) {
            case "Frequent":
                return "Clients who book regular sessions will show up here";
            case "Recent":
                return "No new clients detected in the last month";
            case "Requires Follow-ups":
                return "No follow-up required";
            case "Archived":
                return "You have not archieved any clients";
            case "All":
                return "No client records";
            default:
                return "";
        }
    }


    const getFilteredBodyText = (chip: string, client: ClientDetails) => {
        switch (chip) {
            case "Frequent":
                return `Total Sessions - ${client.report_count}`;
            case "Recent":
                return `Client since ${moment(client.creation_timestamp).format("MMM Do YYYY")}`;
            case "Requires Follow-ups":
                return `Last booking was ${formatTimeAgo(moment(client.last_booking_timestamp ?? 0).toString())}`;
            case "Archived":
                return `Last booking was ${formatTimeAgo(moment(client.last_booking_timestamp ?? 0).toString())}`;
            case "All":
                return `Client since ${moment(client.creation_timestamp).format("MMM Do YYYY")}`;
            default:
                return "";
        }
    }

    const filteredClients = getFilteredClients(selectedChip)
    const searchClients = getClientSearch(searchQuery, userClients.clients)


    return (
        <div>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <div>
                    <Typography variant="h4">Manage Your Clients</Typography>
                    <Typography variant="body1" color="grey" fontSize={14}>
                        View and organize your client details in one place
                    </Typography>
                </div>
                <TextField
                    placeholder="Search clients..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                    sx={{
                        width: 350,
                        marginRight: "5px",
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // Remove border when focused
                            },
                        },
                    }}
                />
            </Stack>
            {searchQuery.length == 0 ?
                <Stack direction="row" spacing={2} mt={2}>
                    {["Frequent", "Recent", "Requires Follow-ups", "Archived", "All"].map((label) => (
                        <Chip
                            key={label}
                            label={label}
                            onClick={() => handleChipClick(label)}
                            sx={{
                                padding: "20px",
                                cursor: "pointer",
                                backgroundColor: selectedChip === label ? "black" : "default",
                                color: selectedChip === label ? "white" : "inherit",
                                opacity: selectedChip === label ? 1 : 0.7,
                                "&:hover": {
                                    backgroundColor: selectedChip === label ? "black" : "default",
                                },
                            }}
                        />
                    ))}
                </Stack> : <Stack direction="row" spacing={2} mt={2}><Chip
                    key={"search"}
                    label={"Search Results"}
                    sx={{
                        padding: "20px",
                        cursor: "pointer",
                        backgroundColor: "black",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "black",
                        },
                    }}
                /></Stack>}
            <Typography variant="body1" display="flex" alignItems="center" mt={2} color={"grey"}>
                <InfoIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                {getDescription(selectedChip)}
            </Typography>
            {searchQuery.length > 0 ? <div>
                {searchClients.length == 0 ? <div className={clientPageStyle.client_container}>
                    <Typography variant="h5" fontWeight={"bolder"}>No Results</Typography>
                    <Typography variant="body1" color={"grey"}>No clients match your search term</Typography>
                    <Typography variant="body2" color={"grey"} mt={"10px"} ></Typography>
                </div> : searchClients.map((c) => <div className={clientPageStyle.client_container}>
                    <Typography variant="h5" fontWeight={"bolder"}>{c.client_name}</Typography>
                    <Typography variant="body1" color={"grey"}>{c.email}</Typography>
                    <Typography variant="body2" color={"grey"} mt={"10px"} fontSize={"12px"}>Client since {moment(c.creation_timestamp).format("MMM Do YYYY")}</Typography>
                </div>)}
            </div> : <>
                {filteredClients.length == 0 ? <div className={clientPageStyle.client_container}>
                    <Typography variant="h5" fontWeight={"bolder"}>{getNoTitle(selectedChip)}</Typography>
                    <Typography variant="body1" color={"grey"}>{getNoSubTitle(selectedChip)}</Typography>
                    <Typography variant="body2" color={"grey"} mt={"10px"} ></Typography>
                </div> : filteredClients.map((c) => <div className={clientPageStyle.client_container}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <div>
                            <Typography variant="h5" fontWeight="bolder">
                                {c.client_name}
                            </Typography>
                            <Typography variant="body1" color="grey">
                                {c.email}
                            </Typography>
                            <Typography variant="body2" color="grey" mt="10px" fontSize="12px">
                                {getFilteredBodyText(selectedChip, c)}
                            </Typography>
                        </div>
                        <div>
                            <Button variant="outlined" color="primary"

                                disableElevation
                                sx={{
                                    width: "150px",
                                    borderRadius: "20px"
                                }}
                                onClick={() => {
                                    navigate(`/client-details/${c.id}`)
                                }}>
                                Details
                            </Button>
                        </div>
                    </Box>
                </div>)}</>}
        </div>
    );
};


const getClientSearch = (searchQuery: string, userClients: ClientDetails[]) => {
    return userClients.filter(client =>
        client.client_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
};

/**
 * Filter out clients who are frequent. Frequent clients should have high booking count.
 */
const getFrequentClients = () => {
    return []
}

/**
 * Filter out clients who are new. Clients who have started therapy in the last month.
 */
const getNewClients = (userClients: ClientDetails[]) => {
    return userClients.filter((details) => (details?.creation_timestamp ?? 0) > moment().subtract(1, "month").unix() * 1000);
}

/**
 * Clients whose last sessions have been greater than two weeks
 * @param userClients 
 * @returns 
 */
const getFollowUps = (userClients: ClientDetails[]) => {
    return userClients.filter((details) => (details?.last_booking_timestamp ?? moment.now()) < moment().subtract(2, "week").unix() * 1000);
}


const getArchieved = () => {
    return []
}