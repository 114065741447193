import React, { useState } from 'react';
import { Box, Typography, IconButton, Tooltip, Snackbar } from '@mui/material';
import { ContentCopy, OpenInNew } from '@mui/icons-material';

const CopyLinkComponent = ({ link }: { link: string }) => {
    const [openToast, setOpenToast] = useState(false); // State to manage toast visibility

    // Function to copy link to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setOpenToast(true); // Show toast on successful copy
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    // Function to open link in a new window
    const openLink = () => {
        window.open(link, '_blank');
    };

    return (
        <Box display="flex" alignItems="center">
            <Typography variant="h6" mr={1}>
                {link}
            </Typography>
            <Tooltip title="Copy link to clipboard">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopy />
                </IconButton>
            </Tooltip>
            <Tooltip title="Preview link">
                <IconButton onClick={openLink}>
                    <OpenInNew />
                </IconButton>
            </Tooltip>

            {/* Snackbar for toast notification */}
            <Snackbar
                open={openToast}
                autoHideDuration={1000} // Toast will hide after 3 seconds
                onClose={() => { setOpenToast(false) }}
                message="Link copied to clipboard!"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the toast
            />
        </Box>
    );
};

export default CopyLinkComponent;
