import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    TextField,
} from '@mui/material';

interface EditDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    editedName: string;
    editedEmail: string;
    setEditedName: (name: string) => void;
    setEditedEmail: (email: string) => void;
}

const EditDialog: React.FC<EditDialogProps> = ({
    open,
    onClose,
    onSave,
    editedName,
    editedEmail,
    setEditedName,
    setEditedEmail,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
        >
            <DialogTitle>Edit Information</DialogTitle>
            <DialogContent>
                <div className="my-4">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                    <TextField
                        id="name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        required
                    />
                </div>
                <div className="my-4">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                    <TextField
                        id="email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={editedEmail}
                        onChange={(e) => setEditedEmail(e.target.value)}
                        required
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} sx={{ borderRadius: "5px", marginRight: "20px" }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
