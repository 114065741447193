import landingpageStyle from "./style/section-five.module.css";
import { useNavigate } from "react-router-dom";
import Growth from "../../../assets/growth.png";
import { motion } from "framer-motion";


const SectionFive = () => {

    const navigate = useNavigate();

    return (
        <div className={landingpageStyle.fifth_section}>

            <div className={landingpageStyle.fifth_section_title}>Level Up In Your Life ⤴️</div>
            <div className={landingpageStyle.fifth_section_subtitle}>Track your growth and unlock new skills</div>

            <div className={landingpageStyle.content_row}>
                <motion.img src={Growth} className={landingpageStyle.growth_img} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }} />

                <div>
                    <div className={landingpageStyle.fifth_section_title}>Gamify Your Personal Growth</div>
                    <div className={landingpageStyle.fifth_section_subtitle}>Earn points, upgrade levels and unlock advanced techniques for a better mental health.</div>
                </div>



            </div>


        </div>)
}

export default SectionFive;