import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, IconButton, Button, Snackbar, Alert } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { Textarea, TextInput } from "flowbite-react"; // Using flowbite TextInput
import { updateSereneUserData } from "../../../helper/user-helper";

export const PersonalDetails = ({ currentName, currentEmail, userId, onSubmit }: { currentName: string, currentEmail: string, userId: string, onSubmit: any }) => {
    const [name, setName] = useState("");
    const [aboutMe, setAboutMe] = useState("I love using Serene ❤️");
    const [socialLinks, setSocialLinks] = useState({ linkedIn: "", website: "" });
    const [error, setError] = useState(false); // To track name field error
    const [aboutMeError, setAboutMeError] = useState(false); // To track "About Me" field error
    const [toastMessage, setToastMessage] = useState(""); // To show toast messages
    const [openToast, setOpenToast] = useState(false); // To toggle toast visibility

    const maxAboutMeLength = 500; // Set the max length for "About Me"

    useEffect(() => {
        setName(currentName);
    }, [currentName]);

    const handleSocialLinkChange = (e: any) => {
        const { name, value } = e.target;
        setSocialLinks((prevLinks) => ({
            ...prevLinks,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        if (!name) {
            setError(true);
            setToastMessage("Please enter your name");
            setOpenToast(true);
        } else if (!aboutMe) {
            setAboutMeError(true);
            setToastMessage("Please enter something about yourself");
            setOpenToast(true);
        } else if (aboutMe.length > maxAboutMeLength) {
            setToastMessage("About Me section exceeds the character limit");
            setOpenToast(true);
        } else {
            setError(false);
            setAboutMeError(false);
            // save to firebase 
            updateSereneUserData(userId, {
                display_name: name,
                about: aboutMe,
                linkedin: socialLinks.linkedIn,
                website: socialLinks.website
            })
            onSubmit()
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false); // Close the toast notification
    };

    return (
        <>
            <Box mt={2}>
                <Typography variant="h6">What do we call you? *</Typography>
                <TextInput
                    required
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-2 w-full"
                    color={error ? "failure" : undefined} // Highlight input if error
                />
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Email *</Typography>
                <TextInput
                    disabled
                    value={currentEmail}
                    className="mt-2 w-full"
                />
            </Box>

            <Box mt={2}>
                <Typography variant="h6">About me *</Typography>
                <Textarea
                    style={{ resize: "none" }}
                    required
                    placeholder="Tell us a little about yourself"
                    value={aboutMe}
                    onChange={(e) => setAboutMe(e.target.value)}
                    className={`mt-2 w-full ${aboutMe.length > maxAboutMeLength ? 'border-red-500' : ''}`} // Turn border red if exceeded
                    color={aboutMeError || aboutMe.length > maxAboutMeLength ? "failure" : undefined} // Highlight input if error or exceeds max length
                    rows={6}
                    maxLength={500}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <Typography variant="body2" color={aboutMe.length > maxAboutMeLength ? "error" : "textSecondary"}>
                        {aboutMe.length}/{maxAboutMeLength} characters
                    </Typography>
                </Box>
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Social Links</Typography>

                <Grid container spacing={2} alignItems="center" mt={0}>
                    <Grid item>
                        <IconButton>
                            <LinkedInIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <TextInput
                            placeholder="LinkedIn (Optional)"
                            name="linkedIn"
                            value={socialLinks.linkedIn}
                            onChange={handleSocialLinkChange}
                            className="w-full"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" mt={0}>
                    <Grid item>
                        <IconButton>
                            <LanguageIcon sx={{ color: "grey" }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <TextInput
                            placeholder="Website (Optional)"
                            name="website"
                            value={socialLinks.website}
                            onChange={handleSocialLinkChange}
                            className="w-full"
                        />
                    </Grid>
                </Grid>
            </Box>
            <br />
            <Box mt={4} display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="primary" onClick={handleSubmit} sx={{ paddingRight: "50px", paddingLeft: "50px", borderRadius: "30px" }}>
                    Next
                </Button>
            </Box>

            {/* Toast Notification */}
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseToast} severity="error" sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PersonalDetails;
