import { createSlice } from '@reduxjs/toolkit'
import { ClientDetails } from '../types';

/**
 * Clients for the user
 */
export const clientSlice = createSlice({
    name: 'clients',
    initialState: {
        fetched: false,
        clients: [] as ClientDetails[],
    },
    reducers: {
        updateClients: (state, action) => {
            state.fetched = true;
            state.clients = action.payload ?? []
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateClients } = clientSlice.actions

export default clientSlice.reducer

