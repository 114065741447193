import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BookingData } from "../../../types";
import { Box, CircularProgress, IconButton, Tooltip, Typography, Chip, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import modifyBookingStyle from "./style/modify-booking.module.css";
import moment from "moment";
import { Helmet } from "react-helmet";
import CancelIcon from '@mui/icons-material/Cancel';

export const ModifyBooking = () => {
    const { bookingId } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [bookingData, setBookingData] = useState<BookingData>()

    useEffect(() => {
        // Fetch the booking details
        const getBookingData = async () => {
            const getBooking = httpsCallable(getFunctions(), 'getBooking');
            const response = await getBooking({ bookingId: bookingId });
            //@ts-ignore
            const bookingData = response.data.event as BookingData;
            console.log(bookingData);
            setBookingData(bookingData);
            setLoading(false);
        };

        if (bookingId) {
            getBookingData();
        }
    }, [bookingId]);

    return (
        <>
            <Helmet>
                <title>Your session details</title>
                <meta name="description" content="Your session details" />
                <meta property="og:title" content="Your session details" />
                <meta property="og:description" content="Details about your booked session." />
                <meta property="og:url" content="https://serene.vybz.health" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://serene.vybz.health/logo.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Your session details" />
                <meta name="twitter:description" content="Details about your booked session." />
                <meta name="twitter:image" content="https://serene.vybz.health/logo.png" />
                <meta name="twitter:url" content="https://serene.vybz.health" />

                <meta property="og:site_name" content="Serene" />
            </Helmet>

            <div>
                {!loading ? (
                    <div className={modifyBookingStyle.container}>
                        <div>
                            <div className={modifyBookingStyle.header}>
                                <Typography variant="h3" fontWeight={"bolder"}>Session Details</Typography>
                                <div onClick={() => navigate(-1)}>
                                    <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                                </div>
                            </div>
                            <Typography variant="h6" fontWeight={"normal"}>
                                {moment(bookingData?.start.dateTime).format("Do MMM HH:mm A")} - {moment(bookingData?.end.dateTime).format("HH:mm A")}
                            </Typography>
                            <br />
                            <Typography variant="h4">Details</Typography>
                            <Typography variant="body1" color={"grey"} fontSize={14}>Information about this session</Typography>
                            <BookingDetails bookingData={bookingData!} />
                            <br />
                            <Typography variant="h4">Attendees</Typography>
                            <Typography variant="body1" color={"grey"} fontSize={14}>Participants and their status</Typography>
                            <div className={modifyBookingStyle.attendee}>
                                {bookingData?.attendees.map((attendee, index) => (
                                    <Box key={index} display="flex" alignItems="center" mb={1}>
                                        <Typography variant="body1" sx={{ marginRight: "10px" }}>
                                            {attendee.email}
                                        </Typography>
                                        <AttendeeStatusBadge responseStatus={attendee.responseStatus!} />
                                    </Box>
                                ))}
                            </div>

                            {/* Buttons at the end of the page */}
                            <Box display="flex" justifyContent="center" mt={8} gap={2}>
                                {bookingData?.htmlLink && (
                                    <Button
                                        variant="outlined"
                                        disableElevation
                                        href={bookingData.htmlLink}
                                        target="_blank"
                                        rel="noopener noreferrer"

                                        sx={{
                                            borderRadius: "20px",
                                            textWrap: "nowrap",
                                            color: "black",
                                            borderColor: "black",
                                            textOverflow: "clip",
                                            overflow: "clip",
                                            "&:hover": {
                                                backgroundColor: "black",
                                                color: "white",
                                                borderColor: "black",
                                            },
                                        }}
                                    >
                                        View
                                    </Button>
                                )}
                                {bookingData?.conferenceData?.entryPoints?.[0]?.uri && (
                                    <Button
                                        variant="outlined"
                                        disableElevation
                                        href={bookingData.conferenceData.entryPoints[0].uri}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            borderRadius: "20px",
                                            color: "black",
                                            textWrap: "nowrap",
                                            borderColor: "black",
                                            textOverflow: "clip",
                                            overflow: "clip",
                                            "&:hover": {
                                                backgroundColor: "black",
                                                color: "white",
                                                borderColor: "black",
                                            },
                                        }}
                                    >
                                        Join
                                    </Button>
                                )}
                            </Box>

                        </div>
                    </div>
                ) : (
                    <Box width={"100vw"} height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress size={60} sx={{ color: "black" }} />
                    </Box>
                )}
            </div>
        </>
    );
};

// Component to render the badge based on attendee response status with info icon
const AttendeeStatusBadge = ({ responseStatus }: { responseStatus: string }) => {
    const statusMapping: { [key: string]: { label: string; color: "success" | "warning" | "error"; description: string } } = {
        "accepted": { label: "Confirmed", color: "success", description: "This attendee has confirmed their participation." },
        "needsAction": { label: "Waiting", color: "warning", description: "This attendee has not yet responded." },
        "declined": { label: "Declined", color: "error", description: "This attendee has declined the invitation." }
    };

    const status = statusMapping[responseStatus] || { label: "Unknown", color: "default", description: "The response status is not recognized." };

    return (
        <Box display="flex" alignItems="center">
            <Chip label={status.label} color={status.color} variant="outlined" sx={{ marginRight: "5px" }} />
            <Tooltip title={`${status.description} You can update your response from your Google Calendar.`}>
                <IconButton sx={{ fontSize: "14px", color: "black" }}>
                    <InfoIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const BookingDetails = ({ bookingData }: { bookingData: BookingData }) => {
    return (
        <div className={modifyBookingStyle.attendee}>
            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <span className="bold" style={{ marginRight: "5px", color: "grey" }}>ID:</span>{bookingData?.extendedProperties?.private?.bookingId ?? ""}
                </Typography>
                <Tooltip title="Unique identifier for this booking. Please use this to report any issues with this booking.">
                    <IconButton sx={{ fontSize: "14px", color: "black" }}>
                        <InfoIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <span className="bold" style={{ marginRight: "5px", color: "grey" }}>Created :</span>{moment(bookingData?.created).format("Do MMM HH:mm A")}
                </Typography>
                <Tooltip title="The date and time this booking was created.">
                    <IconButton sx={{ fontSize: "14px", color: "black" }}>
                        <InfoIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <span className="bold" style={{ marginRight: "5px", color: "grey" }}>Last updated :</span>{moment(bookingData?.updated).format("Do MMM HH:mm A")}
                </Typography>
                <Tooltip title="The date and time this booking was last updated.">
                    <IconButton sx={{ fontSize: "14px", color: "black" }}>
                        <InfoIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    );
};
