import React, { useState } from "react";
import publicPageStyle from "./style/public-page.module.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { TextInput } from 'flowbite-react';
import { Button, Grid, Snackbar, Alert, FormControlLabel, Checkbox } from "@mui/material";

const BookingConfirm = ({ onConfirm }: { onConfirm: any }) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [agreeUpdates, setAgreeUpdates] = useState(true); // New state for checkbox

    const [errorMessage, setErrorMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSubmit = () => {
        if (!name || !email || !phone) {
            let missingFields = [];
            if (!name) missingFields.push("Name");
            if (!email) missingFields.push("Email");
            if (!phone) missingFields.push("Phone Number");

            setErrorMessage(`Missing field(s): ${missingFields.join(", ")}`);
            setOpenSnackbar(true);
        } else {
            // Handle successful booking confirmation
            onConfirm(name, email, phone, agreeUpdates); // Pass the agreeUpdates to onConfirm
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className={publicPageStyle.time_slots_bookings}>
            <Grid container spacing={1.5} sx={{ marginTop: "5px", paddingLeft: "20px", paddingRight: "20px" }}>
                {/* Name */}
                <Grid item xs={12}>
                    <TextInput
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>

                {/* Email */}
                <Grid item xs={12}>
                    <TextInput
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>

                {/* Phone number with area code */}
                <Grid item xs={12}>
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={(v) => setPhone(v as string)} // @ts-ignore for type mismatch warning
                        defaultCountry="US"
                        international
                        countryCallingCodeEditable={false}
                        inputComponent={TextInput}
                    />
                </Grid>

                {/* Checkbox for updates consent */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agreeUpdates}
                                onChange={(e) => setAgreeUpdates(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <span style={{ fontSize: '12px' }}>
                                I agree to receive updates about my booking via WhatsApp or text
                            </span>
                        }
                    />
                </Grid>

                {/* Confirm Button */}
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        sx={{
                            border: "0.1px solid grey",
                            padding: "10px",
                            boxSizing: "border-box",
                            borderRadius: "20px",
                            color: 'white',
                            '&:hover': {
                                border: "0.1px solid black",
                                backgroundColor: 'black', // Disable hover effect
                                opacity: 1, // Keep the opacity the same
                            },
                            backgroundColor: 'black'
                        }}
                    >
                        Confirm Booking
                    </Button>
                </Grid>

                {/* Snackbar for error message */}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={4000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        </div>
    );
};

export default BookingConfirm;
