import React, { useState } from "react";
import { TextField, Box } from "@mui/material";

interface VerificationCodeInputProps {
    onConfirm: (code: string) => void;
}

export const Verification: React.FC<VerificationCodeInputProps> = ({ onConfirm }) => {
    const [code, setCode] = useState<string[]>(["", "", "", ""]);

    const handleChange = (index: number, value: string) => {
        if (isNaN(Number(value))) return; // Only allow numbers

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move focus to the next input when a digit is entered
        if (value && index < 3) {
            document.getElementById(`code-input-${index + 1}`)?.focus();
        }

        // Trigger onConfirm when all slots are filled
        if (newCode.every((digit) => digit !== "")) {
            onConfirm(newCode.join(""));
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        // Move to the previous input on Backspace
        if (event.key === "Backspace" && !code[index] && index > 0) {
            document.getElementById(`code-input-${index - 1}`)?.focus();
        }
    };

    return (
        <Box display="flex" justifyContent="center" gap={1}>
            {code.map((digit, index) => (
                <TextField
                    key={index}
                    id={`code-input-${index}`}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value.slice(-1))}
                    //@ts-ignore
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    variant="outlined"
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center", fontSize: "24px", width: "48px" },
                    }}
                />
            ))}
        </Box>
    );
};

