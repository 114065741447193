import { Chip, fabClasses } from "@mui/material";
import landingpageStyle from "./style/section-four.module.css";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import Meditation from "../../../assets/Meditation.png"
import Journal from "../../../assets/journal.png"
import Affirmations from "../../../assets/Affirmations.png"
import Breathing from "../../../assets/Breathing.png"
import MoodLog from "../../../assets/MoodLog.png"
import MoveMissions from "../../../assets/MoveMissions.png"
import Rest from "../../../assets/Rest.png"
import Imagery from "../../../assets/imagery.png"
import Reflection from "../../../assets/SelfReflection.png"
import 'react-multi-carousel/lib/styles.css';

const PILLS = ["Mindfullness", "Awareness", "Self-Care"]
const SELF_AWARENESS = [Journal, MoodLog, Reflection];
const MINDFULLNESS = [Meditation, Imagery, Breathing];
const SELF_CARE = [Rest, Affirmations, MoveMissions];
// const MOTIVATION = [Journal, Journal, Meditation, Meditation];
// const WELLBEING = [Meditation];
const ITEMS = [MINDFULLNESS, SELF_AWARENESS, SELF_CARE];
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const responsivePills = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};
const SectionFour = () => {
    const [selectedPill, setSelectedPill] = useState(0);
    return (
        <div className={landingpageStyle.fourth_section}>
            <div className={landingpageStyle.fourth_section_title}>Complete "Mood Missions" 🕵️‍♂️</div>
            <div className={landingpageStyle.fourth_section_subtitle}>Discover yourself and enhance your mental health through personalized tasks and lessons designed for your growth</div>
            <br />
            <br />
            <div className={landingpageStyle.caraousal_pills}>
                <Carousel responsive={responsivePills} arrows={false}>
                    {PILLS.map((pill, index) => <div className={landingpageStyle.pills}>
                        <Chip component={"div"}
                            label={pill}
                            clickable sx={{ padding: 2.2, fontSize: 20 }}
                            color={selectedPill == index ? "success" : "default"}
                            variant={selectedPill == index ? "filled" : "outlined"}
                            onClick={() => setSelectedPill(index)} />
                    </div>
                    )}
                </Carousel>
            </div>


            <div className={landingpageStyle.caraousal} >
                <Carousel responsive={responsive}>
                    {
                        ITEMS[selectedPill].map((item, i) => <img key={"img" + i} className={landingpageStyle.mission_img} src={item} />)
                    }
                </Carousel>
            </div>
            <br />
            <br />
            {/* <div className={landingpageStyle.fourth_section_title}>And level up ⬆️</div> */}
        </div >)
}

export default SectionFour;