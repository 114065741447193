import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
    Checkbox,
    ListItemText
} from '@mui/material';
import { TextInput } from 'flowbite-react'; // Importing TextInput from Flowbite

interface LanguageDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    selectedLanguages: string[];
    handleToggleLanguage: (language: string) => void;
    searchTerm: string; // Prop for search term
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>; // Prop for setting the search term
    filteredLanguages: string[]; // New prop for filtered languages
}

const LanguageDialog: React.FC<LanguageDialogProps> = ({
    open,
    onClose,
    onSave,
    selectedLanguages,
    handleToggleLanguage,
    searchTerm,
    setSearchTerm,
    filteredLanguages
}) => {

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Select Languages</DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '400px', // Set a max height for the dialog content
                    overflowY: 'auto', // Enable vertical scrolling
                }}
            >
                {/* Sticky Search Field using Flowbite TextInput */}
                <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <TextInput
                        placeholder="Search Language"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update the search term
                        required
                        className="mb-4" // Adding margin for spacing
                    />
                </div>

                {/* Filtered Language Options */}
                {filteredLanguages.map((language, index) => (
                    <MenuItem key={`${language}_${index}`} value={language} onClick={() => handleToggleLanguage(language)}>
                        <Checkbox checked={selectedLanguages.includes(language)} />
                        <ListItemText primary={language} sx={{ marginLeft: "5px" }} />
                    </MenuItem>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} sx={{ borderRadius: "5px", marginRight: "20px" }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LanguageDialog;
