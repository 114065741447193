import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Button, Select, MenuItem, IconButton, FormControl
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { getFirebaseFirestore, SERENE_USERS } from '../../../const';
import { doc, updateDoc } from 'firebase/firestore';
import moment from 'moment-timezone';
import { Availability, DaysOfWeek, Slot } from '../../../types';
import { TextInput } from 'flowbite-react';

const daysOfWeek: DaysOfWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const defaultSlots = {
    start_time: '10:00',
    end_time: '17:00',
};

const sessionLengths = [45, 60]; // in minutes
const availableTimezones = moment.tz.names(); // Get all available timezones

const ScheduleSetting = ({ onSuccess, userId, buttonText = "Continue", currentAvailability }: { onSuccess: any, userId: string, buttonText?: "Continue" | "Save", currentAvailability?: Availability }) => {
    const [availability, setAvailability] = useState<Availability>(() => {
        return daysOfWeek.reduce((acc, day) => {
            acc[day] = [{ ...defaultSlots }];
            return acc;
        }, {} as Record<DaysOfWeek, Slot[]>);
    });

    const [sessionLength, setSessionLength] = useState(60); // Default session length (in minutes)
    const [timezone, setTimezone] = useState(moment.tz.guess()); // Default to user's local timezone
    const [isSaved, setIsSaved] = useState(false); // Track if saved button state

    useEffect(() => {
        if (currentAvailability != null) {
            setAvailability(currentAvailability)
        }
    }, [currentAvailability])

    const handleTimezoneChange = (event: any) => {
        setTimezone(event.target.value);
    };

    const handleAddSlot = (day: string) => {
        setAvailability((prev: any) => ({
            ...prev,
            [day]: [...prev[day], { ...defaultSlots }],
        }));
    };

    const handleRemoveSlot = (day: string, index: number) => {
        setAvailability((prev: any) => ({
            ...prev,
            [day]: prev[day].filter((_: any, i: any) => i !== index),
        }));
    };

    const handleTimeChange = (day: string, index: number, field: 'start_time' | 'end_time', value: string) => {
        setAvailability((prev: any) => ({
            ...prev,
            [day]: prev[day].map((slot: Slot, i: any) =>
                i === index ? { ...slot, [field]: value } : slot
            ),
        }));
    };

    const validateAvailability = () => {
        for (const day in availability) {
            //@ts-ignore
            const slots = availability[day];
            for (let slot of slots) {
                if (slot.end_time <= slot.start_time) {
                    return false;
                }
            }
        }
        return true;
    };

    const saveAvailability = async () => {
        if (!validateAvailability()) {
            alert('End time must be after start time for all slots.');
            return;
        }

        const db = getFirebaseFirestore();
        const availabilityData = {};

        for (const day in availability) {
            //@ts-ignore
            availabilityData[day] = availability[day].map((slot: Slot) => ({
                start_time: slot.start_time,
                end_time: slot.end_time,
            }));
        }

        try {
            await updateDoc(doc(db, SERENE_USERS, userId), {
                availability: availabilityData,
                timezone,
            });

            setIsSaved(true);
            onSuccess();
            setTimeout(() => setIsSaved(false), 1000); // Revert button text after 1 second
        } catch (error) {
            console.error('Error saving availability:', error);
        }
    };

    return (
        <Box p={6}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
                {daysOfWeek.map((day) => (
                    <Box key={day} width={{ xs: '100%', md: '30%' }} mb={3}>
                        <Typography variant="body1" fontWeight={"bolder"}>{`${day[0].toUpperCase()}${day.substring(1)}`}</Typography>
                        <br />
                        {availability[day]?.map((slot: Slot, index: any) => (
                            <Box key={index} display="flex" alignItems="center" gap={2} mb={1}>
                                <TextInput
                                    type="time"
                                    value={slot.start_time}
                                    onChange={(e: any) => handleTimeChange(day, index, 'start_time', e.target.value)}
                                    sizing="md"
                                />
                                <TextInput
                                    type="time"
                                    value={slot.end_time}
                                    onChange={(e: any) => handleTimeChange(day, index, 'end_time', e.target.value)}
                                    sizing="md"
                                />
                                <IconButton onClick={() => handleRemoveSlot(day, index)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        ))}
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => handleAddSlot(day)}
                        >
                            Add Slot
                        </Button>
                    </Box>
                ))}
            </Box>

            <Box mt={4}>
                <Typography variant="h6">Timezone</Typography>
                <br />
                <FormControl fullWidth variant="standard">
                    <Select
                        value={timezone}
                        onChange={handleTimezoneChange}
                    >
                        {availableTimezones.map((tz) => {
                            const offset = moment.tz(tz).utcOffset() / 60;
                            const sign = offset >= 0 ? "+" : "-";
                            const formattedTimezone = `(GMT ${sign}${Math.abs(offset).toString().padStart(2, '0')}:00) ${tz}`;
                            return (
                                <MenuItem key={tz} value={tz}>
                                    {formattedTimezone}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            <Box mt={4}>
                <Typography variant="h6">Session Length</Typography>
                <br />
                <Select
                    value={sessionLength}
                    onChange={(e) => setSessionLength(e.target.value as number)}
                    displayEmpty
                    fullWidth
                >
                    {sessionLengths.map((length) => (
                        <MenuItem key={length} value={length}>
                            {`${length} minutes`}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box mt={4} display="flex" justifyContent="flex-end">
                <Button
                    onClick={saveAvailability}
                    variant="outlined"
                    color={isSaved ? 'success' : 'primary'}
                    size='large'
                    sx={{
                        borderRadius: "20px", '&:hover': {
                            opacity: 1,
                        },
                    }}
                >
                    {isSaved ? "Saved" : buttonText}
                </Button>
            </Box>
        </Box>
    );
};

export default ScheduleSetting;
