import { motion } from "framer-motion";
import VybzAppLogo from "../assets/logo.svg";
import headerStyle from "./style/header.module.css";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Header = ({ product = "" }) => {
    const navigate = useNavigate();
    const [headerOpacity, setHeaderOpacity] = useState(1);

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY < 5) {
                setHeaderOpacity(1);
                return;
            }

            if (currentScrollY < lastScrollY) {
                setHeaderOpacity(1);

            } else {

                setHeaderOpacity(0);

            }

            lastScrollY = currentScrollY;
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Array to store button labels and their corresponding routes
    const navItems = [
        { label: "About Us", path: "/about" },
        // { label: "Research", path: "/research" },
        // { label: "Contact", path: "/contact" },
        // { label: "Blog", path: "/blog" },
    ];

    return (
        <motion.div
            className={headerStyle.header}
            style={{ opacity: headerOpacity, }} // Control opacity with smooth transition
        >
            <img src={VybzAppLogo} className={headerStyle.header_logo} onClick={() => { navigate("/") }} />
            <div className={headerStyle.header_title}>
                {product === "serene" ? (
                    <div onClick={() => { navigate("/") }} className={headerStyle.header_title_non_bold}>Serene</div>
                ) : (
                    <>
                        <span onClick={() => { navigate("/") }} className={headerStyle.header_title_non_bold}> Vybz{" "}</span>
                        <span onClick={() => { navigate("/") }} className={headerStyle.header_title_non_bold}>Health</span>
                    </>
                )}
            </div>
            <div className={headerStyle.spacer} />
            {/* Dynamically Generated Text Buttons */}
            <div className={headerStyle.nav_buttons}>
                {navItems.map((item) => (
                    <Button
                        key={item.label}
                        onClick={() => navigate(item.path)}
                        variant="text"
                        size="small"
                        disableRipple
                        disableElevation
                        sx={{
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                    >
                        <Typography >{item.label}</Typography>
                    </Button>
                ))}
            </div>
            <div className={headerStyle.login}>
                <Button
                    onClick={() => {
                        navigate("/login");
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ borderRadius: "20px", paddingLeft: "30px", paddingRight: "30px" }}
                >
                    <Typography>Login</Typography>
                </Button>
            </div>
        </motion.div>
    );
};

export default Header;
