import { createSlice } from '@reduxjs/toolkit';
import { NotificationData } from '../types';

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        unread: 0,
        notifications: [] as NotificationData[],
    },
    reducers: {
        readNotifications: (state, action) => {
            state.unread = 0;
        },
        incrementUnreadNotifications: (state, action) => {
            state.unread = state.unread + action.payload;
        },
        updateNotificationData: (state, action) => {
            // Add the new notification(s) to the state
            if (Array.isArray(action.payload)) {
                // If payload is an array, merge it with existing notifications
                state.notifications = [
                    ...state.notifications,
                    ...action.payload,
                ];
            } else {
                // If payload is a single notification, add it
                state.notifications.push(action.payload);
            }

            // Sort notifications in descending order of timestamps
            state.notifications.sort((a, b) => b.timestamp - a.timestamp);
        },
    },
});

export const { incrementUnreadNotifications, updateNotificationData, readNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
