import { Box, Button, IconButton, StepLabel, Typography, CircularProgress } from "@mui/material";
import schedulingStyle from "./style/scheduling.module.css"
import { useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { getFirebaseDatabase } from "../../../const";
import { onValue, ref } from "firebase/database";
import { useSelector } from "react-redux";
import { AppState } from "../../../types";
import ScheduleSetting from "./schedule-setting";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Close } from "@mui/icons-material";
import CopyLinkComponent from "./copy-link";
import { EventAvailable } from '@mui/icons-material';
import completeAnimation from '../../../assets/complete_animation.json';
import { generateUniqueUsername } from "../../../helper/user-helper";
import Lottie from "lottie-react";

export const Scheduling = () => {
    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [connectLoading, setConnectLoaing] = useState(false)

    const getStepSubtitle = () => {
        switch (step) {
            case 0:
                return "Connect Your Calendar";
            case 1:
                return "Set Your Schedule";
            case 2:
                return "Done";
            case 3:
                return "🎉 Complete";
            default:
                return "";
        }
    };

    const getStepDesc = () => {
        switch (step) {
            case 0:
                return "Serene will create events on your calendar whenever a booking is made. It will also take care of informing participants ahead of time, reminding them about the upcoming sessions, so you stay on track effortlessly";
            case 1:
                return "Add your working schedule so that Serene ensures that bookings are made only during the given slots. You can always customize it later from your calendar screen";
            case 3:
                return "Customize your booking page to reflect your unique style and preferences. Add your branding elements and personalized messaging to create a professional yet welcoming experience for your clients";
            case 2:
                return "Your personal booking page is ready! Share your personalized link with clients, allowing them to easily book appointments with you";
            default:
                return "";
        }
    };

    const getStepBody = () => {
        switch (step) {
            case 0:
                return <Button variant="contained" size="large" disableElevation
                    sx={{
                        width: "350px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingRight: "40px",
                        paddingLeft: "40px",
                        '&:hover': {
                            backgroundColor: 'black', // Disable hover effect
                            opacity: 1, // Keep the opacity the same,
                        },
                        borderRadius: "30px",
                        background: "black"
                    }}
                    onClick={constStartOauth}
                    endIcon={
                        connectLoading ? (
                            <CircularProgress size={22} sx={{ color: 'white', marginLeft: 1 }} /> // Loading spinner
                        ) : (
                            <EventAvailable sx={{ color: 'white', marginLeft: 1, }} /> // Default icon
                        )
                    }>
                    Connect Google Calendar
                </Button>;
            case 1:
                return <ScheduleSetting userId={sereneUser.id} onSuccess={async () => {
                    await generateUniqueUsername(sereneUser.email, sereneUser.id)
                    setStep(2);
                }} />;
            case 2:
                return <div style={{ padding: "50px" }}>
                    <Lottie animationData={completeAnimation} style={{ height: "250px" }} loop={false} />
                    <br />
                    <CopyLinkComponent link={`https://serene.vybz.health/user/${sereneUser.userName}`} />;
                </div>
            default:
                return null;
        }
    };



    // Example usage:

    // Handle the connection flow
    const constStartOauth = async () => {
        const authWindow = window.open('', '_blank');
        authWindow?.document.write('Loading preview...');

        if (!authWindow) {
            return;
        }

        try {
            setConnectLoaing(true)
            const startCalenderOauth = httpsCallable(getFunctions(), "startCalenderOauth");
            const response = await startCalenderOauth();
            const authUrl = (response.data as any).authUrl ?? "";
            authWindow!.location.href = authUrl;
            authWindow?.focus();

            // Start a listener which will listen for a successful oauth connection and move the user to the next step
            onValue(ref(getFirebaseDatabase(), `calender_auth/${sereneUser.id}`),
                (snapshot) => {
                    if (snapshot.exists()) {
                        const calAuthStatus = snapshot.val();
                        const status = calAuthStatus.status;

                        if (status === "complete") {
                            setStep(1);
                            setConnectLoaing(false)
                            authWindow?.close();
                        }
                    }
                });

        } catch (error) {
            console.error(error);
            setConnectLoaing(false)
        }
    };

    return (
        <div className={schedulingStyle.container}>
            <Stepper activeStep={step}>
                {["Calender", "Schedule", "Complete"].map((label, key) => {
                    return <Step key={key}>
                        <StepLabel>{label}</StepLabel>
                    </Step>;
                })}
            </Stepper>
            <br />
            <br />
            <div>
                <Typography variant="h3">{getStepSubtitle()}</Typography>
            </div>
            <br />
            <div className={schedulingStyle.desc}>
                <Typography variant="body1" color={"grey"} fontSize={"16px"}>{getStepDesc()}</Typography>
            </div>
            <div className={schedulingStyle.action}>
                {getStepBody()}
            </div>
            <br />
            <div>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        onClick={() => { setStep(step - 1); }}
                        disabled={step === 0}
                        sx={{ marginRight: "10px" }}
                    >
                        <ArrowBack sx={{ color: 'black', fontSize: 30, }} />
                    </IconButton>
                    <IconButton
                        onClick={() => { navigate("/dashboard"); }}
                    >
                        <Close sx={{ color: 'black', fontSize: 30, }} />
                    </IconButton>
                </Box>
                <br />
            </div>
        </div>
    );
};
